.profile-page__title {
    margin-bottom: 33px;
    text-align: center;
}

.profile-page h4 {
    margin-bottom: 30px;
}

.profile-heading {
    margin-bottom: 45px;
}

.profile-avatar {
    text-align: center;
    margin: 0 auto;
}

.profile-avatar-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 15px;
}

.profile-avatar-file-input {
    display: none;
}

.profile-avatar-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-avatar-text {
    font-size: 14px;
    cursor: pointer;
}

.profile-page .form-group {
    margin-bottom: 26px;
}

.profile-info {
    padding-bottom: 47px;
}

.profile-data h4 {
    padding-top: 27px;
    border-top: 1px solid #e3eded;
}

.profile-data form {
    padding-bottom: 37px;
}

.profile-data form:last-of-type{
    padding-bottom: 0;
}

.profile-data label {
    display: none;
}

.profile-data .render-field-container {
    margin: 0 0 20px;
}

.profile-data .form-group .render-field-error {
    top: -30px;
}

@media screen and (min-width: 768px) {
    .profile-page__title {
        font-size: 30px;
    }
    .profile-page__title {
        width: 50%;
        text-align: left;
    }
    .profile-heading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .profile-avatar {
        width: 50%;
    }
    .profile-data h4 {
        padding-top: 0;
        border-top: none;
    }
    .profile-data form {
        border-bottom: none;
    }
    .profile-info {
        padding-bottom: 0;
    }
}