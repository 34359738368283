    .vacancies-list__button {
    margin-top: 35px;
}

.vacancy-item-actions-wrap {
    margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
    .archive .vacancy-item-actions-wrap{
        width: 50%;
    }
}

.vacancy-item-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vacancy-item-actions label {
    margin-bottom: 0;
}

.vacancy-item-switch {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.vacancy-item-switch-title {
    margin-left: 12px;
    color: #b7beca;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.react-switch-bg {
    background: #14ccae;
}

.vacancies-icons-wrap {
    display: flex;
}

.vacancies-list .icon-wrap {
    margin-right: 20px;
}

.vacancies-list .item-icon {
    margin-right: 0;
}

.vacancy-item-title {
    display: block;
    color: #354169;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 15px;
    word-wrap: break-word;
}

.vacancy-item-description-elem--publication {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .vacancy-item-actions-wrap {
        order: 2;
        margin-bottom: 0;
    }
    .vacancy-item-switch {
        max-width: 86px;
    }
    .vacancy-item-actions label {
        order: 2;
    }

    .vacancy-item-switch {
        flex-direction: column;
        align-items: flex-start;
    }

    .vacancy-item-switch-title {
        margin-left: 0;
        margin-top: 16px;
    }

    .vacancy-item-actions {
        align-items: flex-start;
    }

}

@media screen and (min-width: 992px) {
    .vacancy-item-description-elem--publication {
        width: unset;
    }

    .vacancies-list .item-icon {
        margin-right: 15px;
    }

    .vacancy-item-actions {
        justify-content: flex-end;
    }

    .vacancy-item-switch {
        margin-left: 25px;
    }

    .icon-wrap:last-child {
        margin-right: 0;
    }

    .vacancy-item-title {
        font-size: 30px;
        margin-bottom: 23px;
        line-height: 1;
    }

    .vacancy-item-switch-title {
        margin-top: 24px;
        white-space: nowrap;
    }

    .vacancy-item-actions-wrap {
        padding-top: 3px;
    }

}

@media screen and (min-width: 1200px) {
    .vacancy-item-title {
        max-width: 520px;
    }
    .vacancies-list .icon-wrap {
        margin-right: 10px;
    }
}

@media screen and (max-width: 576px){
    .archive-col-xs-info{
        max-width: 80%;
    }
    .archive-col-xs-buttons{
        max-width: 20%;
    }
}

.vacancies-filter-open-box {
    color: #02a88d;
    cursor: pointer;
}

.vacancies-filter-open-box.archive{
    margin-top: 10px;
    margin-right: 15px;
    margin-left: auto;
}
@media screen and (max-width: 576px){
    .vacancies-filter-open-box.archive{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}


.vacancies-filter-open-box > .vacancies-filter-icon {
    color: #b2e2db;
    margin-right: 15px;
}

.vacancies-filter-open-box > .vacancies-filter-chevron-icon {
    color: #02a88d;
    margin-left: 15px;
    font-size: 10px;
}

.vacancies-page-filter-container {
    display: block;
    margin: 0 0 25px 0;
}

.vacancies-wrap div.react-select__control,
.all-vacancies-content div.react-select__control  {
    background-color: #fff;
}

.vacancies-page-filter-container {
    margin: 0 -15px 25px -15px;
    background: #e9eeef;
    padding: 15px;
    display: block;
}

@media screen and (min-width: 767px) {
    .vacancies-filter-address {
        display: flex;
    }
    .vacancies-filter-address>div {
        flex-basis: 100%;
    }

    .vacancies-wrap .vacancies-filter-address > div:first-child{
        margin-right: 6px;
    }

    .vacancies-wrap .vacancies-filter-address > div:last-child{
        margin-left: 6px;
    }

    .vacancies-page-filter-container {
        margin-left: 0;
        margin-right: 0;
        border-radius: 10px;
    }

    .vacancies-page-multiple {
        -webkit-animation: rising .2s;
        animation: rising .2s;
    }
    .mobile-container-page-sort {
        display: none;
    }
}

.vacancies-page-filter-container div.react-select__control {
    height: auto;
    min-height: 40px;
}

.vacancies-page-filter-container div.react-select-menu-is-close,
.vacancies-page-filter-container div.react-select-menu-is-open {
    margin-bottom: 10px;
}

.vacancies-page-filter-container div.react-select__value-container--is-multi.react-select__value-container--has-value {
    padding-left: 10px;
}

.vacancies-page-filter-container div.react-select__multi-value {
    padding: 7px 7px 7px 12px;
    margin: 5px;
    border-radius: 15px;
    min-height: 30px;
    background-color: #dff5f1;
    font-size: 100%;
}

.vacancies-page-filter-container div.react-select__multi-value__label {
    color: #02a88d;
    padding: 0;
}

.vacancies-page-filter-container div.react-select__multi-value__remove {
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
    color: #02a88d;
}

.vacancies-page-filter-container div.react-select__multi-value__remove:hover {
    color: #354169;
    background-color: #dff5f1;
}

.vacancies-page-sort div.react-select-menu-is-close,
.vacancies-page-sort div.react-select-menu-is-open {
    display: inline-block;
    width: 160px;
    margin: 10px 0 10px 30px;
}

.vacancies-page-sort.archive{
    justify-content: space-between;
}

.vacancies-page-sort .checkbox-container {
    margin-bottom: 0;
}

.mobile-container-page-sort .checkbox-container {
    margin: 0;
}

.vacancies-page-sort {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.vacancies-page-sort .checkbox-mark, .container-title-checkbox .checkbox-mark, .response-item-name .checkbox-mark, .mobile-container-page-sort .checkbox-mark {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(80,80,80, .3), 0 2px 2px rgba(164,164,164, .3);
}

.vacancies-page-sort-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.vacancies-page-multiple .button-add-item {
    margin-right: 10px;
}

.add-vacancy-button {
    margin-top: 10px;
}

.import-new-vacancy-item {
    font-size: 17px;
    margin-bottom: 5px;
}

.import-old-vacancy-item {
    font-size: 17px;
    color: #02a88d;
    margin-bottom: 5px;
}

.import-old-vacancy-item :hover {
    color: #02a88d;
}

.import-vacancies-wrapper .button:nth-child(2) {
    margin-left: 15px;
 }

.import-vacancies-wrapper .button {
    font-size: 12px;
    height: 35px;
}


.import-hh-vacancies-drop-list > button {
    color: #02a88d;
    font-size: 18px;
}

.import-vacancies-wrapper .button-add-item span {
    margin: 0;
    font-size: 14px;
}

@media screen and (min-width: 992px) {
    .vacancies-page-multiple .button-add-item {
        margin-right: 15px;
    }
    .add-vacancy-button {
        margin-top: 0;
    }
}

@media screen and (max-width: 968px) and (min-width: 768px) {
    .vacancies-page-sort:not(.archive) .vacancies-page-sort-container{
        width: 100%;
        justify-content: space-between;
    }
}

@media screen and (max-width: 768px) {
    .import-new-vacancy-item,
    .import-old-vacancy-item {
        font-size: 14px;
    }
    .vacancies-page-sort-container {
        margin-left: 0;
        width: 100%;
        justify-content: space-between;
    }
    .vacancies-page-sort.archive .vacancies-page-sort-container {
        width: 180px;
        margin-left: auto;
        justify-content: flex-end;
    }
}
.vacancies-page-multiple {
    display: flex;
    margin-bottom: 10px;
}

.vacancies-page-multiple .vacancy-item-description-elem {
    margin-left: 8px;
}

.button-multiple, .multiple-select, .mobile-button-multiple-menu, .mobile-container-page-sort {
    -webkit-animation: rising .2s;
    animation: rising .2s;
}

.multiple-select {
    margin-right: 5px;
    margin-left: 0;
}


@keyframes rising {
    0% {
        -webkit-transform: scale(1.0, 0);
        transform: scale(1.0, 0);
    }
    100% {
        -webkit-transform: scale(1.0, 1.0);
        transform: scale(1.0, 1.0);
    }
}

.vacancies-icons-wrap-button, .icons-wrap-button {
    margin-bottom: 10px;
    margin-top: 10px;
}

.button-multiple {
    margin-top: 3px;
}

.mobile-button-multiple-menu {
    display: none;
}

@media screen and (max-width: 576px) {
    .vacancies-wrap .main-page-title {
        width: 100%;
    }
    .vacancies-icons-wrap-button, .icons-wrap-button {
        margin-bottom: 20px;
        margin-top: 13px;
    }
    .vacancies-page-multiple {
        display: none;
    }
    .button-multiple {
        margin-right: 10px;
        margin-top: -5px;
    }
    .multiple-select {
        margin-left: 0;
    }

    .mobile-button-multiple-menu {
        display: flex;
        margin-bottom: 16px;
        margin-top: 11px;
        position: relative;
        margin-left: auto;
    }
    .mobile-button-multiple-menu .vacancy-item-description-elem {
        font-size: 14px;
        height: 100%;
        display: inline-block;
    }
    .mobile-button-multiple-menu .vacancy-item-description-elem::first-letter {
        text-transform: uppercase !important;
    }
    .mobile-button-multiple-menu .item-icon {
        padding-top: 2px;
    }
    .mobile-multiple-menu {
        position: absolute;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        z-index: 1;
        right: 0;
        top: 20px;
        background-color: white;
    }
    .mobile-multiple-menu .item-icon {
        display: none;
    }
    .mobile-container-page-sort {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .mobile-container-page-sort .react-select-menu-is-close{
        margin-left: auto;
    }

    .mobile-container-page-sort.archive .react-select-menu-is-close,
    .mobile-container-page-sort .react-select-menu-is-close{
        margin-right: auto;
        margin-left: 0;
    }

    @media screen and (min-width: 424px) and (max-width: 576px){
        .mobile-container-page-sort .react-select-menu-is-close{
            margin-right: 0;
            margin-left: auto;
        }
    }

    .mobile-container-page-sort div.react-select-menu-is-close,
    .mobile-container-page-sort div.react-select-menu-is-open {
        width: 150px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .mobile-container-page-sort .checkbox-container {
        word-wrap: break-word;
        max-width: 50%;
    }
    .mobile-container-page-sort .checkbox-mark {
        top: 50%;
        margin-top: -8px;
    }
    .mobile-multiple-menu button {
        display: block;
        margin: 20px 10px;
    }
}

@media screen and (max-width: 470px) {
    .import-vacancies-import-button-wrapper {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .import-vacancies-wrapper .button:nth-child(2) {
        margin-left: 0;
     }
}

@media screen and (max-width: 430px) {
    .vacancies-page-sort div.react-select-menu-is-close,
    .vacancies-page-sort div.react-select-menu-is-open {
        margin-left: 0;
    }
}

@media screen and (max-width: 520px) {
    .vacancies-page-sort .checkbox-container {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 576px) {
    .vacancies-page-sort {
        flex-direction: column;
        align-items: start;
    }

    .vacancies-page-selected-actions {
        width: 100%;
        justify-content: space-between;
    }
}




