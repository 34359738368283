.header {
    display: flex;
    background-color: #202535;
    justify-content: center;
}

.header-wrapper {
    padding: 0;
}

.header-nav-menu {
    list-style: none;
    margin: 0;
    padding: 0
}

.logo-wrapper:hover {
    transition: .5s;
}

.header-nav {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding-right: 15px;
}

.header_nav-menu-item {
    display: inline-block;
    transition: color 0.5s ease;
}

.header_nav-menu-item:last-child {
    color: #fff;
    border-radius: 15px;
    border: 1px solid #0dc1b6;
    padding: 5px 10px;
}

.header_nav-menu-item:last-child:hover {
    background-color: #0dc1b6; 
}

.header_nav-menu-item:last-child:hover .header_nav-menu-item-link{
    color: #fff;
}

.header_nav-menu-item-link {
    display: block;
    padding: 0 15px;
    transition: color 0.5s ease;
    color: #fff;
}

.navigation-logo {
    background-size: 60%;
    margin: 0;
    background-position-y: 41%;
}

.menu-logo-public {
    display: flex;
    width: 250px;
    height: 80px;
    background: #202535 url('../../assets/img/logo_white.svg') no-repeat 20px 50%;
    background-size: 69%;
    cursor: pointer;
}

@media screen and (max-width: 574px) {
    .menu-logo-public {
        width: 80px;
        height: 35px;
        background: #202535 url('../../assets/img/logo_white_adaptive.svg') no-repeat 20px;
    }

    .header_nav-menu-item-link {
        font-size: 12px;
    }

    .header {
        padding: 15px 0;
    }
}