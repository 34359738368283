.full-height-wrap {
    min-height: 100vh;
    padding: 45px 0;
    margin: 0 -15px;
}

.agreement-text-top {
    color: #768a8c;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 60px;
}

.agreement-section {
    margin-bottom: 60px;
}

.agreement-section:last-child {
    margin-bottom: 0;
}

.agreement-title {
    color: #4d5658;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.75px;
    margin-bottom: 30px;
}

.agreement-text {
    color: #768a8c;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.33px;
    margin-bottom: 28px;
}

.agreement-text:last-child {
    margin-bottom: 0;
}

.default-img {
    max-width: 300px;
    width: 100%;
    height: 200px;
    margin-bottom: 45px;
}

.default-img img {
    display: block;
    width: 100%;
}

.error-section {
    padding: 0 15px;
}

.error-section__title {
    color: #354169;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.75px;
    margin-bottom: 25px;
}

.error-section__link {
    margin-bottom: 20px;
}

.error-section__text {
    color: #768a8c;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.section-grey:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eff4f4;
    z-index: -1;
}

.section-grey_sm {
    max-width: 600px;
    margin: 0 auto;
}

.vacancy-item-description-list, .response-item-description-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
}

.vacancy-item-description-elem, .response-item-description-elem {
    color: #b7beca;
    font-size: 12px;
    font-weight: 400;
    margin-right: 17px;
    margin-bottom: 5px;
}

.vacancy-item-description-elem:last-child, .response-item-description-elem:last-child {
    margin-right: 0;
}

@media screen and (min-width: 480px) {
    .full-height-wrap {
        padding-top: 70px;
        margin: 0;
    }
}

@media screen and (min-width: 992px) {
    .vacancy-item-description-elem, .response-item-description-elem {
        font-size: 14px;
    }
}

@media screen and (min-width: 1200px) {
    .vacancy-item-description-elem, .response-item-description-elem {
        position: relative;
        margin-right: 40px;
    }
    .vacancy-item-description-elem:after, .response-item-description-elem:after {
        position: absolute;
        right: -20px;
        top: 2px;
        content: '';
        width: 1px;
        height: 16px;
        background-color: #dfe9e9;
    }

    .vacancy-item-description-elem:last-child:after, .response-item-description-elem:last-child:after {
        display: none;
    }
}