/* custom buttons */

button {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
}
button:focus {
    outline: none !important;
}

.archive-vacancy-button {
    margin-top: 14px;
}

.button {
    display: inline-block;
    height: 45px;
    padding: 0 30px;
    border-radius: 22px;
    color: #ffffff;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    background-color: #14ccae;
    transition: all 0.15s;
}
.button:hover {
    background-color: #02a88d;
    border-color: #02a88d;
}
.button[disabled] {
    background-color: #e8ebf5;
    color: #a1a9c2;
}
.button_color_dark-blue {
    background-color: #354169;
}
.button_color_dark-blue:hover {
    background-color: #182346;
}
.button_size_sm {
    height: 35px;
    font-size: 12px;
    padding: 0 25px;
    letter-spacing: 1.38px;
}
.button_plus {
    width: 35px;
    height: 35px;
    padding: 0 0 2px;
    line-height: unset;
}
.button_plus .button-icon {
    font-size: 24px;
    padding-left: 2px;
}
.button_plus .button-text {
    display: none;
}

.icon_crown {
    transition: .2s;
    margin: auto;
}

.icon_crown:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.button-add-item{
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #0bb79b;
    background: transparent;
    border: 0;
    padding: 0;
}
.button-add-item span {
    margin-right: 7px;
}
.button-add-item:hover {
    color: #047b63;
    transition: color 0.3s ease;
}


.button-tag-item {
    max-width: 100%;
    border: 1px solid #354169;
    background-color: #fff;
    border-radius: 3px 3px 3px 3px;
    color: #354169;
    display: inline-block;
    line-height: 1;
    text-align: left;
    padding: 7px 10px;
    word-wrap: break-word;
    transition: all 0.15s;
}
.button-tag-item:hover {
    color: #fff;
    background-color: #354169;
}


.button-frame {
    width: 100%;
    background-color: #fff;
    color: #0bb79b;
    font-size: 18px;
    border-radius: 10px;
    border: 1px solid rgba(11, 183, 155, 0.5);
    letter-spacing: 0.7px;
    height: 57px;
    line-height: 57px;
}
.button-frame:hover {
    border: 1px solid rgba(11, 183, 155, 1);
    background-color: #fff;
}


.button-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: #eff4f4;
    border-radius: 50%;
    flex-shrink: 0;
}
.button-circle svg {
    font-size: 12px;
    color: #354169;
}
.button-circle_color_light-green {
    background-color: #14ccae;
    transition-duration: 500ms;
}
.button-circle_color_gray {
    background-color: #eff4f4;
    cursor: default;
    opacity: 0.3;
    border: 1px solid #354169;
    transition-duration: 500ms;
}
.button-circle_color_dark-blue {
    background-color: #354169;
}
.button-circle_color_light-green svg,
.button-circle_color_dark-blue svg {
    color: #fff;
}

.button-dash {
    display: inline-block;
    height: 35px;
    padding: 0 22px;
    border-radius: 22px;
    color: #0aa78d;
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    border: 1px dashed #0aa78d;
}
.button-dash__icon {
    display: inline-block;
    margin-right: 10px;
}

/* buttons containers */

.create-field-form > .buttons-circle-container{
    margin-left: 20px;
    width: 100px;
}

@media screen and (max-width: 767px){
    .create-field-form > .buttons-circle-container{
        width: auto;
    }
}

@media screen and (max-width: 767px) {
    .buttons-circle-container {
        justify-content: flex-end;
    }
}

.buttons-circle-container {
    display: flex;
}
.buttons-circle-container .button-circle {
    margin-right: 10px;
}
.buttons-circle-container .button-circle:last-child {
    margin-right: 0;
}


.buttons-container .button {
    width: 100%;
    margin: 10px 15px 10px 0;
}
.buttons-container .button:last-of-type {
    margin-right: 0;
}

@media screen and (min-width: 576px) {
    .buttons-container .button {
        width: unset;
    }
    .button-select-all {
        order: -1;
    }
}

@media screen and (max-width: 576px) {
    .button-select-all {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .button_plus {
        display: inline-flex;
        align-items: center;
        width: unset;
        padding: 0 20px;
    }
    .button_plus .button-icon {
        font-size: 18px;
        padding-bottom: 2px;
    }
    .button_plus .button-text {
        display: inline;
        font-size: 12px;
        margin-left: 10px;
        letter-spacing: 1.38px;
    }


    .button-add-item {
        font-size: 16px;
    }

    .button-frame {
        height: 70px;
        line-height: 70px;
    }


    .button-circle {
        width: 36px;
        height: 36px;
    }
    .button-circle svg {
        font-size: 15px;
    }
}

.DateRangePicker__CalendarSelection--is-pending {
    background-color: #0bb79b !important;
}

.DateRangePicker__CalendarSelection {
    background-color: #0bb79b !important;
    border-color: #0bb79b !important;
}

.DateRangePicker__CalendarHighlight--single {
    background-color: #fff;
    border: 1px solid #0bb79b !important;
}

.DateRangePicker__MonthDates > table {
    font-family: 'Ubuntu', sans-serif;
}