.form-group {
    margin-bottom: 24px;
}

.form-group label {
    color: #a3a4a9;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
    margin-bottom: 13px;
}

.date-response-filter {
    background-color: white !important;
    margin: 7px;
    width: auto;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}

.date-filter-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 20px;
}

.date-filter-container-decorator {
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-around;
    margin-left: 20px;
    border-radius: 30px 30px 30px 30px;

}

.date-filter-container-decorator input {
    margin: 0;
    box-shadow: none;
    width: 85px;
    padding-left: 0;
    padding-right: 0;
}

.react-datepicker {
    font-family: inherit !important;
    background-color: #FFFFFF !important;
}
.react-datepicker__day--selected {
    background-color: #06b291 !important;
}

@media screen and (max-width: 577px) {
    .date-filter-container-decorator input {
        padding-left: 10px;
        padding-right: 10px;
        width: 100px;
    }
    .react-datepicker__input-container {
        padding-left: 0;
    }
}

@media screen and (min-width: 578px) {
    .react-datepicker__input-container {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 767px) {
    .date-filter-container{
        flex-direction: column;
        align-items: flex-start;
        padding-top: 10px;
    }
    .date-filter-container-decorator{
        margin-bottom: 20px;
        margin-left: 0px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 575px) {
    .date-filter-container-decorator {
        background-color: transparent;
        flex-direction: column;
    }
    .date-filter-container-decorator .date-filter-dash {
        display: none;
    }
    .date-filter-container-decorator input:last-child {
        margin-top: 15px;   
    }
}


.form-control {
    border-radius: 23px;
    background-color: #eff4f4;
    font-size: 16px;
    padding: 8px 22px;
    height: unset;
    color: #354169;
    border: none;
}

.form-control::-webkit-input-placeholder {
    color: #787c7d;
}
.form-control::-moz-placeholder {
    color: #787c7d;
}
.form-control:-ms-input-placeholder {
    color: #787c7d;
}
.form-control:-moz-placeholder {
    color: #787c7d;
}

.form-group .render-field-error {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 5px 21px;
    background-color: #d86155;
    border-radius: 8px;
}

.render-field-item .render-field-error {
    top: -30px;
}

.form-file-container .render-field-error {
    right: auto;
    top: -10px;
}

.form-group .render-field-error .error-text {
    display: block;
    position: relative;
    color: #eff4f4;
    font-size: 12px;
    letter-spacing: 0.3px;
    z-index: 2;
}

.form-group .render-field-error .error-text:before {
    display: block;
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #d86155;
    left: 50%;
    transform: translateX(-50%) rotate(135deg);
    bottom: -9px;
    z-index: 1;
}

/* correct */

.form-control:focus {
    color: #354169;
    border: none;
    box-shadow: none;
    background-color: #eff4f4;
}

.template-editing-form-heading .form-group label,
.render-actions-wrap .form-group label,
.create-field-form label,
.render-field-head label {
    padding-left: 23px;
}

/* selects */

div.react-select__control {
    height: 40px;
    border-radius: 23px;
    background-color: #eff4f4;
    font-size: 16px;
    border: none;
    transition: unset;
}

.react-select-menu-is-open div.react-select__control {
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.react-select__control.react-select__control--is-focused {
    box-shadow: none;
}

div.react-select__value-container {
    padding: 0 20px;
}

div.react-select__input,
div.react-select__single-value {
    color: #354169;
}

div.react-select__menu {
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    margin: 0;
    box-shadow: none;
    z-index: 30;
}

div.react-select__menu-list {
    padding: 0;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    border: 1px solid #b9cece;
    border-top: none;
}

div.react-select__option {
    padding-left: 22px;
    color: #8e97b2;
    font-size: 14px;
    background-color: #fff !important;
    word-wrap: break-word;
}

div.react-select__option:last-child {
    padding-bottom: 13px;
}

div.react-select__option--is-focused {
    background-color: #e5eaed !important;
}

span.react-select__indicator-separator {
    display: none;
}

div.react-select__dropdown-indicator {
    padding-right: 18px;
    color: #354169;
}

div.react-select__dropdown-indicator:hover {
    color: #354169;
}

div.react-select__dropdown-indicator svg {
    width: 17px;
    height: 17px;
    transition: transform ease 0.3s;
}

.react-select-menu-is-open div.react-select__control .react-select__dropdown-indicator svg {
    transform: rotate(180deg);
}

.react-select__input > input {
    opacity: 1 !important;
}

.react-select-menu-is-close div.react-select__control {
    border: 1px solid transparent;
}

.react-select-menu-is-open div.react-select__control {
    background-color: #fff;
    border: 1px solid #b9cece;
    border-bottom: 1px solid transparent;
}

.react-select__control .react-select__placeholder {
    color: #787c7d;
}

.react-select__control.react-select__control--is-disabled div.react-select__dropdown-indicator,
.react-select__control.react-select__control--is-disabled .react-select__placeholder,
.react-select__control.react-select__control--is-disabled .react-select__single-value--is-disabled {
    color: #9d9d9f;
}

/* textarea */

textarea {
    resize: none;
}
