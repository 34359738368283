.radio-container {
    display: flex;
}

.radio {
    display: block;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #354169;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}

.radio-container__item {
    margin-right: 40px;
}

.radio-container__item:last-child {
    margin-right: 0;
}

.radio-hidden {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #e1eaea;
    border-radius: 50%;
}

.radio-mark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-hidden:checked ~ .radio-mark:after {
    display: block;
}

.radio-container .radio-mark:after {
    top: 5px;
    left: 5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #354169;
}
