.footer {
    margin-top: 30px;
    padding: 20px 12%;
    font-size: 20px;
    height: 80px;
    color: #fff;
    background-color: #354169;
}

.footer-text {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #fff;
    margin-bottom: 6px;
}

.footer-email {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #b8c8e7;
}

.footer-email span {
    color: #14ccae;
}

@media screen and (min-width: 1600px) {
    .footer {
        padding: 20px 18%;
    }
}