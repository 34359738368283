.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    color: #354169;
    font-size: 14px;
    padding-left: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-hidden {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border-radius: 4px;
    background-color: #eee;
}

.checkbox-mark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkbox-mark:after {
    display: block;
}

.checkbox-container .checkbox-mark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 8px;
    border: solid #354169;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container-title-checkbox {
    display: flex;
}

.container-title-checkbox .checkbox-container{
    margin-bottom: 31px;
}

.container-title-checkbox .checkbox-mark, .response-item-name .checkbox-mark {
    top: 38%;
}