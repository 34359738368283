.full-height-wrap {
    min-height: 100vh;
    padding: 45px 0;
    margin: 0 -15px;
}

.agreement-text-top {
    color: #768a8c;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 60px;
}

.agreement-section {
    margin-bottom: 60px;
}

.agreement-section:last-child {
    margin-bottom: 0;
}

.agreement-title {
    color: #4d5658;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.75px;
    margin-bottom: 30px;
}

.agreement-text {
    color: #768a8c;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.33px;
    margin-bottom: 28px;
}

.agreement-text:last-child {
    margin-bottom: 0;
}

.default-img {
    max-width: 300px;
    width: 100%;
    height: 200px;
    margin-bottom: 45px;
}

.default-img img {
    display: block;
    width: 100%;
}

.error-section {
    padding: 0 15px;
}

.error-section__title {
    color: #354169;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.75px;
    margin-bottom: 25px;
}

.error-section__link {
    margin-bottom: 20px;
}

.error-section__text {
    color: #768a8c;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.section-grey:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eff4f4;
    z-index: -1;
}

.section-grey_sm {
    max-width: 600px;
    margin: 0 auto;
}

.vacancy-item-description-list, .response-item-description-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: 5px;
}

.vacancy-item-description-elem, .response-item-description-elem {
    color: #b7beca;
    font-size: 12px;
    font-weight: 400;
    margin-right: 17px;
    margin-bottom: 5px;
}

.vacancy-item-description-elem:last-child, .response-item-description-elem:last-child {
    margin-right: 0;
}

@media screen and (min-width: 480px) {
    .full-height-wrap {
        padding-top: 70px;
        margin: 0;
    }
}

@media screen and (min-width: 992px) {
    .vacancy-item-description-elem, .response-item-description-elem {
        font-size: 14px;
    }
}

@media screen and (min-width: 1200px) {
    .vacancy-item-description-elem, .response-item-description-elem {
        position: relative;
        margin-right: 40px;
    }
    .vacancy-item-description-elem:after, .response-item-description-elem:after {
        position: absolute;
        right: -20px;
        top: 2px;
        content: '';
        width: 1px;
        height: 16px;
        background-color: #dfe9e9;
    }

    .vacancy-item-description-elem:last-child:after, .response-item-description-elem:last-child:after {
        display: none;
    }
}
.breadcrumbs {
    padding-bottom: 60px;
}

@media screen and (max-width: 576px) {
    .breadcrumbs {
        padding-bottom: 30px;
    }
}

.breadcrumbs__link {
    color: #b7beca;
    text-decoration: underline;
}

.breadcrumbs__divider {
    margin: 0 15px;
    color: #b7beca;
}

.breadcrumbs__item {
    color: #b7beca;
    word-wrap: break-word;
}
/* login and registration */

.auth-content {
    max-width: 450px;
    background-color: #fff;
    margin: 0 auto;
    padding: 55px 35px 35px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}

.auth-content .auth-btns-wrap {
    padding-top: 35px;
}

.auth-content .auth-btns-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.auth-content .auth-license-text {
    color: #96a0b1;
    font-size: 14px;
    letter-spacing: 0.35px;
    margin-top: 20px;
    line-height: 1.3;
}

h2.auth-title {
    max-width: 450px;
    margin: 0 auto 30px;
    color: #354169;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    word-wrap: break-word;
}

.auth-text {
    color: #354169;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
}

.auth-btns-wrap .button {
    width: 100%;
    margin-bottom: 15px;
}

/* email sent */

.sign-up-success-content {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 130px 15px 0;
    text-align: center;
}

.sign-up-success-content .icon-wrap {
    margin-bottom: 50px;
}

.sign-up-success-content .icon-wrap .item-icon {
    font-size: 50px;
    color: #00b491;
}

.sign-up-success-page-title {
    color: #354169;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}

.sign-up-success-page-text {
    color: #354169;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 60px;
}

@media screen and (min-width: 480px) {
    .auth-content {
        border-radius: 10px;
    }
    .auth-content .auth-btns-wrap {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .auth-content .auth-license-text {
        margin-top: 35px;
    }
    h2.auth-title {
        text-align: left;
    }
    .sign-up-success-content {
        padding: 130px 0 0;
    }
    .auth-btns-wrap .button {
        width: unset;
        margin: 0 15px 0 0;
    }
}

/* hamburger */

.hamburger {
    padding: 0;
    display: inline-block;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    cursor: pointer;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; }
.hamburger:hover {
    opacity: 0.7; }
.hamburger.is-active:hover {
    opacity: 0.7; }
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: #2e354c; }

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #354169;
    border-radius: 4px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
.hamburger-inner::before {
    top: -10px; }
.hamburger-inner::after {
    bottom: -10px; }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/* navigation */


.navigation {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

.navigation.active {
    bottom: 0;
}

.navigation-panel-top {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 70px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px 15px;
    z-index: 10;
}

.menu-logo,
.navigation-company, .navigation-logout {
    display: none;
}

/* menu */

.menu-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #2e354c;
    padding: 115px 0 40px 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: all 0.3s;
    overflow-y: scroll;
    z-index: 9;
}

.menu-wrap.active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.menu {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.menu-select-title {
    display: block;
    color: #b8c8e7;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
}

.menu-select {
    margin-bottom: 35px;
    padding-left: 20px;
}

.navigation-panel-top-company .active-team-toggle-selector {
    min-width: 205px;
}

.menu-select .active-team-toggle-selector {
    min-width: 280px;
}

.menu-link-disabled,
.menu-link-disabled .icon-wrap .item-icon {
    color: #b8c8e770 !important;
}

.menu-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    color: #b8c8e7;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
    padding: 15px 5px 15px 20px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
}

.menu-link-tooltip-margin {
    left: 186px !important;
}

.menu-link:not(.menu-link-disabled):hover .icon-wrap .item-icon,
.menu-link:not(.menu-link-disabled):hover .menu-link-text {
    color: #fff;
}

.menu-link:not(.menu-link-disabled).active:hover .icon-wrap .item-icon,
.menu-link:not(.menu-link-disabled).active:hover .menu-link-text,
.menu-inner-link.active:hover {
    color: #b8c8e7;
}

.menu-link-red-dot {
    height: 9px;
    width: 9px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #ae3636;
    position: absolute;
    left: 26px;
    top: 13px;
    z-index: 1;
}

.menu-logout {
    color: #18dcb6;
    font-size: 14px;
    font-weight: 400;
}

.menu-link .icon-wrap {
    width: 18px;
    margin-right: 25px;
}

.menu-link .icon-wrap .item-icon {
    color: #b8c8e7
}

.menu-logout-wrap .icon-wrap {
    width: 16px;
    margin-right: 20px;
}

.menu-logout-wrap .icon-wrap .item-icon {
    font-size: 14px;
    color: #18dcb6;
}

.menu-logout-wrap {
    display: -webkit-flex;
    display: flex;
    padding-left: 20px;
    margin-top: 35px;
}

.navigation-panel-top-moved {
    transition: margin-left 0.3s;
}

.navigation-panel-top-title-company,
.navigation-panel-top-company,
.navigation-panel-top-logout {
    display: none;
}

.navigation-panel-top-title-company {
    color: #b7beca;
    font-size: 14px;
    font-weight: 400;
}

.menu-inner-link {
    display: block;
    color: #b8c8e7;
    padding: 15px 20px 15px 64px;
    font-size: 14px;
    letter-spacing: 0.35px;
}

.menu-inner-link:hover {
    color: #fff;
}

.menu-link .icon-dropdown {
    margin-left: 45px;
}

.menu-link:not(.menu-link-disabled):hover .icon-dropdown {
    color: #fff;
}

.icon-dropdown {
    transition: -webkit-transform ease 0.3s;
    transition: transform ease 0.3s;
    transition: transform ease 0.3s, -webkit-transform ease 0.3s;
}

.menu-link-is-open .icon-dropdown {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.icon-dropdown-svg {
    font-size: 12px;
}

/* nav-person */

.navigation-panel-top-person {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.navigation-panel-top-person-name {
    color: #354169;
    font-size: 16px;
    font-weight: 700;
    margin-right: 15px;
    cursor: pointer;
}

.navigation-panel-top-person-avatar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 45px;
    margin-right: 15px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}
.navigation-panel-top-person-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .navigation {
        position: static;
        overflow: hidden;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .navigation.active {
        bottom: unset;
    }
    .navigation-panel-top-person {
        margin-right: 40px;
    }
    .navigation-panel-top-profile {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }
    .navigation-panel-top-logout {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        cursor: pointer;
    }
    .navigation-panel-top-logout-text {
        color: #b7beca;
        font-size: 12px;
        margin: 0 10px 0 0;
    }
    .navigation-panel-top-logout:hover .navigation-panel-top-logout-text,
    .navigation-panel-top-logout:hover .icon-wrap .item-icon {
        color: #2e354c;
    }
    .menu-logout-wrap,
    .menu-select {
        display: none;
    }
    .navigation-panel-top {
        min-height: 80px;
        padding-right: 80px;
    }
    .menu-wrap {
        position: fixed;
        top: 0;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        padding-top: 0;
        max-width: 80px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 0.3s;
        z-index: 11;
    }
    .menu-overflow {
        width: 265px;
    }
    .menu-wrap.active {
        max-width: 265px;
    }
    .menu-logo-helper-hidden {
        position: absolute;
        right: 0;
        top: 0;
        width: 15px;
        height: 80px;
        background-color: #202535
    }
    .menu-logo {
        display: -webkit-flex;
        display: flex;
        width: 250px;
        height: 80px;
        margin-bottom: 25px;
        background: #202535 url(/static/media/logo_white.b213b02a.svg) no-repeat 20px 50%;
        background-size: 69%;
        cursor: pointer;
    }
    .menu-logo img {
        object-fit: contain;
        margin-left: 21px;
    }
    .menu-link {
        padding-left: 32px;
    }
    .menu-link .icon-wrap {
        margin-right: 32px;
    }
    .menu-link-red-dot {
        left: 38px;
    }
    .navigation-panel-top-company {
        display: block;
    }
    .navigation-panel-top-moved {
        margin-left: 85px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }
    .navigation-panel-top-company {
        margin-left: 30px;
    }
    .navigation-panel-top-moved.active {
        margin-left: 265px;
    }
    .menu-link.active .icon-wrap {
        position: relative;
    }
    .menu-link.active .icon-wrap:before {
        position: absolute;
        display: block;
        content: '';
        top: -8px;
        left: -10px;
        width: 35px;
        height: 35px;
        border-radius: 5px;
        background-color: #000000;
        opacity: 0.1;
    }
    .menu-wrap.active .menu-link.active .icon-wrap:before {
        display: none;
    }
    .menu-wrap.active .menu-link.active,
    .menu-wrap.active .menu-inner-link.active {
        background-color: #202535;
    }
    .menu-item {
        position: relative;
    }
    .menu-inner-link-wrap {
        display: none;
    }
    .menu-inner-link {
        padding-left: 82px;
    }
    .menu-wrap.active .menu-inner-link-wrap {
        display: block;
    }
}

@media screen and (min-width: 992px) {
    .navigation-panel-top-title-company {
        display: block;
        margin-left: 35px;
    }
    .navigation-panel-top-company {
        margin-left: 20px;
    }
}

@media screen and (min-width: 1600px) {
    .menu-wrap {
        max-width: 265px;
    }
    .navigation-panel-top-moved {
        margin-left: 250px;
    }
    .menu-inner-link-wrap {
        display: block;
    }
    .menu-link.active .icon-wrap:before {
        display: none;
    }
    .menu-wrap .menu-link.active {
        background-color: #202535;
    }
    .hamburger {
        display: none;
    }
    .menu-inner-link.active {
        background-color: #202535;
    }
}

/* Отклики */

.response-item-title {
    margin-bottom: 25px;
}

.response-item-new-label {
    color: #e02730;
    margin-bottom: 2px;
}

.response-item-position {
    color: #354169;
    font-size: 18px;
    margin-bottom: 7px;
    word-wrap: break-word;
}

.response-item-name-link {
    color: #26b9a1;
    font-size: 24px;
    word-wrap: break-word;
    max-width: 90%;
}

.responses-wrap .react-select-menu-is-close div.react-select__control {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}

.responses-wrap div.react-select__control {
    background-color: #fff;
}

@media screen and (min-width: 992px) {
    .response-item-position {
        font-size: 18px;
    }
    .response-item-name-link {
        font-size: 30px;
    }
}
.applicant__item {
    margin-bottom: 24px;
}

.applicant__label {
    margin-bottom: 13px;
}

.response-left__edit-icon {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 5px;
    z-index: 2;
}

.response-question {
    margin-bottom: 24px;
}

.response-question__label {
    margin-bottom: 13px;
}

/* INFO */

.response-info {
    padding-top: 45px;
}

.response-info__item {
    margin-bottom: 25px;
}

.response-info__label {
    margin-bottom: 10px;
}

.vacancy-title .application-status {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.response-info__vacancy-change-menu-wrapper {
    display: -webkit-flex;
    display: flex;
}

.response-info__vacancy-change-menu-wrapper .react-select__menu-notice--no-options {
    font-size: 14px;
}

.response-info__vacancy-change-menu {
    min-width: 80%;
    margin-top: -5px;
}

.response-info__move-response-btn {
    margin-top: 2px;
    color: #c3c9d0;
}

.response-info__move-response-btn:hover {
    cursor: pointer;
}

.icon-green {
    color:#0bb79b;
}

.response-info__value.vacancy-title svg {
    margin-right: 10px;
}

.response-info__value {
    color: #354169;
    font-size: 22px;
    word-wrap: break-word;
}

.application-status {
    display: -webkit-flex;
    display: flex;
}

.application-status__reset-button {
    margin-left: 15px;
}

.application-status__reset-button:hover,
.response-info__move-response-btn:hover {
    color: #31384d;
}

/* TAGS */

.response-tags {
    padding-bottom: 45px;
    border-bottom: 1px solid #e3eded;
}

.response-tags__item {
    margin: 2px 5px 3px 0;
}

/* COMMENTS */

.comments {
    padding: 45px 0 0;
}

.comments__title {
    margin-bottom: 15px;
}

.comments__item {
    padding: 27px 0;
    border-top: 1px solid #e3eded;
}

.comment__info {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px;
}

.comments__item:nth-child(2) {
    border-top: none;
}

.comment__user-icon {
    padding-top: 2px;
    margin-right: 20px;
}

.comment__user-name {
    font-size: 16px;
    color: #10816f;
    letter-spacing: 0.4px;
}

.comment__icons-container {
    padding-left: 15px;
    margin-left: auto;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.comment__icons-container :last-child {
    margin-left: 20px;
}

.comment-textarea {
    min-height: 120px;
}

.no-comments {
    padding: 25px 0 35px;
    font-size: 16px;
    color: #354169;
}

td.no-comments {
    text-align: center;
}

th {
    font-weight: normal;
}

@media screen and (min-width: 768px) {
    .response-info {
        padding-top: 0;
    }
    .response-tags {
        border-bottom: none;
        padding-bottom: 15px;
    }
}

@media screen and (min-width: 992px) {
    .response-info {
        padding-top: 0;
    }
    .response-info__label {
        margin-bottom: 0;
        padding-top: 8px;
    }
    .comment__flex {
        display: -webkit-flex;
        display: flex;
    }
    .comment__user-name {
        margin-right: 18px;
    }
    .response-info__item:first-child {
        margin-bottom: 0px;
        min-height: 50px;
    }
    .response-info__label.vacancy-title {
        padding-top: 5px;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin-bottom: 0;
    }
}

.responses-filter-open-box {
    color: #02a88d;
    cursor: pointer;
}

.responses-filter-open-box .responses-filter-icon {
    color: #b2e2db;
    margin-right: 15px;
}

.responses-filter-open-box .responses-filter-chevron-icon {
    color: #02a88d;
    margin-left: 15px;
    font-size: 10px;
}

.responses-page-filter-container {
    margin: 0 -15px 25px -15px;
    background: #e9eeef;
    display: block;
    padding: 10px 0;
}

.responses-page-filter-container div.react-select-menu-is-close,
.responses-page-filter-container div.react-select-menu-is-open {
    margin: 10px;
}

.responses-page-filter-container .checkbox-mark {
    background-color: #fff;
}

.responses-checkboxes-group .checkbox-container {
    display: block;
}

.responses-checkboxes-group .checkbox-container:first-child {
    margin-bottom: 20px;
}

.responses-checkboxes-group {
    margin: 10px 20px;
    display: inline-block;
    width: calc( 50% - 40px );
}

@media screen and (min-width: 576px) {

    .responses-filter-open-box {
        margin-bottom: 0;
    }

    .responses-page-filter-container {
        margin-left: 0;
        margin-right: 0;
        border-radius: 10px;
        padding: 10px;
    }

    .responses-checkboxes-group {
        margin: 10px 0;
        width: auto;
    }

    .responses-checkboxes-group .checkbox-container:first-child {
        margin-bottom: 0;
    }

    .responses-checkboxes-group .checkbox-container {
        margin: 15px 15px 5px 15px;
        width: auto;
        display: inline-block;
    }
    
    .responses-page-filter-container div.react-select-menu-is-close,
    .responses-page-filter-container div.react-select-menu-is-open {
        display: inline-block;
        width: 300px;
    }
}

.response-item-name {
    display: -webkit-flex;
    display: flex;
}


.form-file-container {
    position: relative;
    left: 5px;
    padding-top: 20px;
}

.form-file {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
}

.form-file-info {
    margin-left: 15px;
}

.form-group .button-add-item {
    color: #0bb79b;
}

@media screen and (min-width: 768px) {
    .form-group .button-add-item {
        font-size: 16px;
    }
}

.form-file-container .button-add-item {
    cursor: pointer;
}

.search-response-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    background-color: white;
}

.search-response-container > .search-response__input {
    margin-bottom: 0;
    width: 100%;
    background-color: white;
}

.search-response-container > .search-response__input:focus {
    background-color: white;
}

@media screen and (max-width: 576px){
    .search-response-container{
        margin-bottom: 40px;
    }    
}

@media screen and (max-width: 576px) {
    .responses-wrap .vacancies-page-sort .multiple-select,
    .responses-wrap .vacancies-page-sort .mobile-button-multiple-menu {
        margin-top: -40px;
        margin-bottom: 0;
    }
    .responses-wrap .vacancies-page-sort .button-multiple {
        margin-top: -37px;
        margin-bottom: 0;
    }
    .mobile-multiple-menu button {
        white-space: nowrap;
    }
}

@media screen and (max-width: 376px) {
    .responses-wrap .main-page-title {
        width: 100%;
    }
}

.response-history {
    padding: 45px 0 0;
}

.content-changes-history {
    white-space: nowrap;
    font-size: 14px;
    color: #354169;
}

@media screen and (max-width: 1446px) {
    .content-changes-history {
        font-size: 12px;
    }

    .response-history .table td, .table th {
        padding: 0.55rem;
    }
}

@media screen and (max-width: 1276px) {
    .content-changes-history {
        font-size: 12px;
    }

    .response-history .table td, .table th {
        padding: 0.5rem;
    }
}

@media screen and (max-width: 1088px) {
    .response-right .table-responsive {
        overflow-x: auto !important;
        width: 105%;
    }
}

@media screen and (max-width: 991px) {
    .response-right .table-responsive {
        width: 120%;
    }
}

@media screen and (max-width: 768px) {
    .response-right .table-responsive {
        overflow-x: visible !important;
        overflow-x: initial !important;
        width: 105%;
    }
}

@media screen and (max-width: 606px) {
    .response-right .table-responsive {
        overflow-x: auto !important;
    }
}


.content-changes-history-title {
    white-space: nowrap;
    color:  #a3a4a9;
}

.response-top {
    margin-bottom: 60px;
}

.response-top__back-button {
    color: #02a88d;
    font-size: 18px;
    position: relative;
}

.response-top__back-button_arrow {
    position: absolute;
    padding-right: 30px;
    left: -45px;
}
.team-container-collapse {
    width: 100%;
}

.team-container-toggle {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 0 35px;
}

.team-container-toggle-header {
    width: calc(100% - 48px);
}

.teams-item-tariff {
    font-size: 14px;
    font-weight: 400;
    color: #354169;
}

.team-name-editor-container {
    display: -webkit-flex;
    display: flex;
    min-height: 40px;
    margin-bottom: 10px;
}

.team-name-editor-container-button-right {
    margin-left: auto;
    margin-right: 8px;
}

.team-name-editor-container .icon-wrap {
    width: 28px;
    height: 28px;
    padding: 0;
    line-height: 28px;
    background-color: #eff4f4;
    border-radius: 50%;
    text-align: center;
    margin-left: auto;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    cursor: pointer;
}

.team-name-editor-container .icon-wrap.icon-editor {
    margin-left: unset;
}

.team-name-editor-container-title {
    padding: 7px 30px 7px 0;
    font-weight: 700;
    word-wrap: break-word;
    min-width: 0;
}

.active-team-toggle-selector {
    min-width: 180px;
    max-width: 230px;
}

.customization-form-row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin: 5px 0;
}

/* table */

table.teams-table {
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    width: unset;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 0 35px;
    margin-bottom: 0;
}

table.teams-table thead {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
    padding-bottom: 10px;
}

table.teams-table tr {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

table.teams-table tbody {
    display: -webkit-flex;
    display: flex;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 10px;
}

table.teams-table thead th {
    color: #aab3bd;
    font-size: 14px;
    font-weight: 400;
    border-bottom: none;
    padding: 12px 23px;
    height: 40px;
    border-left: none;
}

table.teams-table td, table.teams-table th {
    padding: 12px 45px 12px 20px;
    height: 40px;
    color: #354169;
    font-size: 13px;
    font-weight: 400;
    border-left: 1px solid #dee2e6;
    word-wrap: break-word;
}

table.teams-table th:first-child,
table.teams-table td:last-child {
    border-top: none;
}

table.teams-table td.team-table-td-without-delete {
    border-left: none;
    display: none;
}

td.team-table-td-with-delete{
    text-align: end;
}

@media screen and (min-width: 992px){
    table.teams-table td.team-table-td-with-delete {
        border-left: none;
    }
}

@media screen and (max-width: 991px){
    td.team-table-td-with-delete{
        text-align: start;
    }
}

table.teams-table th:first-child {
    width: 100%;
}
.teams-table_status-row{
    text-align: center;
    border-top: none !important;
}

/* buttons */

.team-container-buttons-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.team-container-buttons-container .button {
    margin: 15px 15px 15px 0;
}

.team-name-editor-container .form-group {
    margin: 0 10px 0 0;
}

@media screen and (min-width: 768px) {
    .team-container-toggle {
        padding: 0;
    }
    table.teams-table {
        margin-right: 0;
        margin-left: 0;
        padding: 35px 0;
    }
    .team-container-buttons-container .button {
        margin: 0;
    }
    .team-container-toggle-header {
        width: calc(100% - 56px);
    }
    .job-link-button {
        font-size: 16px;
    }
}

@media screen and (min-width: 992px) {
    .team-container-buttons-container {
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    table.teams-table {
        display: table;
        width: 100%;
        margin: 35px 0;
    }
    table.teams-table thead {
        display: table-header-group;
    }
    table.teams-table tr {
        display: table-row;
    }
    table.teams-table td {
        display: table-cell;
    }
    table.teams-table tbody {
        display: table-row-group;
    }
    table.teams-table tr:last-child th:first-child {
        border-bottom: none;
    }
    table.teams-table thead tr:last-child th:first-child {
        border-bottom: 1px solid #dee2e6;
    }
    table.teams-table thead tr th {
        border-left: 1px solid #dee2e6;
        border-top: none;
        padding-left: 19px;
    }
    table.teams-table th:first-child {
        width: 175px;
        border-left: none;
        border-bottom: 1px solid #dee2e6;
    }
    table.teams-table th {
        width: 175px;
    }
    table.teams-table td:last-child {
        width: 50px;
        border-top: 1px solid #dee2e6;
    }
    table.teams-table td, table.teams-table th {
        padding: 12px 20px 12px 20px;
    }
    table.teams-table .teams-table-delete {
        border-left: none;
    }
    table.teams-table thead th {
        border-bottom: 1px solid #dee2e6;
    }
    table.teams-table td:first-child {
        text-align: center;
        border-left: none;
    }
    .teams-table_status-row {
        width: 10%;
        border-top: 1px solid #dee2e6 !important;
    }
}

.job-link-button {
    border-radius: 23px;
    background-color: #eff4f4;
    padding: 8px 22px;
    color: #354169;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.job-link {
    display: inline-block;
    margin-left: 5px;
    max-width: 380px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}

.job-link a {
    color: #b7beca;
}

.job-link-content {
    display: inline;
}

.job-link-content:after {
    content: ':';
}

.button-add-worker {
    margin-bottom: 15px;
}

@media screen and (max-width: 1200px) {
    .job-link, .job-link-content:after {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .button-add-worker {
        width: 100%;
        text-align: right;
    }
}

@media screen and (max-width: 767px) {
    .job-link-button {
        -webkit-order: -1;
                order: -1;
    }
    .button-add-worker {
        margin-bottom: 0;
    }
    .team-container-buttons-container .button {
        margin-right: 0;
    }
}

@media screen and (max-width: 400px) {
    .team-container-buttons-container {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
    .button-add-worker {
        -webkit-order: -2;
                order: -2;
        margin-bottom: 15px;
    }
    .team-container-buttons-container .button, .job-link-button {
        width: 100%;
        margin-bottom: 0;
    }
}

.job-link-button .item-icon {
    margin-left: 5px;
}

@media screen and (max-width: 968px) {
    .widget-code-modal .modal-content {
        width: 90vw;
    }
}

@media screen and (max-width: 768px) {
    .widget-code-modal__copy-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.job-link-button .copy-icon {
    margin-left: 7px;
}

.invite-employee-error {
    margin-top: 20px;
    color: red;
}
    .vacancies-list__button {
    margin-top: 35px;
}

.vacancy-item-actions-wrap {
    margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
    .archive .vacancy-item-actions-wrap{
        width: 50%;
    }
}

.vacancy-item-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.vacancy-item-actions label {
    margin-bottom: 0;
}

.vacancy-item-switch {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0;
}

.vacancy-item-switch-title {
    margin-left: 12px;
    color: #b7beca;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.react-switch-bg {
    background: #14ccae;
}

.vacancies-icons-wrap {
    display: -webkit-flex;
    display: flex;
}

.vacancies-list .icon-wrap {
    margin-right: 20px;
}

.vacancies-list .item-icon {
    margin-right: 0;
}

.vacancy-item-title {
    display: block;
    color: #354169;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 15px;
    word-wrap: break-word;
}

.vacancy-item-description-elem--publication {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .vacancy-item-actions-wrap {
        -webkit-order: 2;
                order: 2;
        margin-bottom: 0;
    }
    .vacancy-item-switch {
        max-width: 86px;
    }
    .vacancy-item-actions label {
        -webkit-order: 2;
                order: 2;
    }

    .vacancy-item-switch {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .vacancy-item-switch-title {
        margin-left: 0;
        margin-top: 16px;
    }

    .vacancy-item-actions {
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

}

@media screen and (min-width: 992px) {
    .vacancy-item-description-elem--publication {
        width: unset;
    }

    .vacancies-list .item-icon {
        margin-right: 15px;
    }

    .vacancy-item-actions {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }

    .vacancy-item-switch {
        margin-left: 25px;
    }

    .icon-wrap:last-child {
        margin-right: 0;
    }

    .vacancy-item-title {
        font-size: 30px;
        margin-bottom: 23px;
        line-height: 1;
    }

    .vacancy-item-switch-title {
        margin-top: 24px;
        white-space: nowrap;
    }

    .vacancy-item-actions-wrap {
        padding-top: 3px;
    }

}

@media screen and (min-width: 1200px) {
    .vacancy-item-title {
        max-width: 520px;
    }
    .vacancies-list .icon-wrap {
        margin-right: 10px;
    }
}

@media screen and (max-width: 576px){
    .archive-col-xs-info{
        max-width: 80%;
    }
    .archive-col-xs-buttons{
        max-width: 20%;
    }
}

.vacancies-filter-open-box {
    color: #02a88d;
    cursor: pointer;
}

.vacancies-filter-open-box.archive{
    margin-top: 10px;
    margin-right: 15px;
    margin-left: auto;
}
@media screen and (max-width: 576px){
    .vacancies-filter-open-box.archive{
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }
}


.vacancies-filter-open-box > .vacancies-filter-icon {
    color: #b2e2db;
    margin-right: 15px;
}

.vacancies-filter-open-box > .vacancies-filter-chevron-icon {
    color: #02a88d;
    margin-left: 15px;
    font-size: 10px;
}

.vacancies-page-filter-container {
    display: block;
    margin: 0 0 25px 0;
}

.vacancies-wrap div.react-select__control,
.all-vacancies-content div.react-select__control  {
    background-color: #fff;
}

.vacancies-page-filter-container {
    margin: 0 -15px 25px -15px;
    background: #e9eeef;
    padding: 15px;
    display: block;
}

@media screen and (min-width: 767px) {
    .vacancies-filter-address {
        display: -webkit-flex;
        display: flex;
    }
    .vacancies-filter-address>div {
        -webkit-flex-basis: 100%;
                flex-basis: 100%;
    }

    .vacancies-wrap .vacancies-filter-address > div:first-child{
        margin-right: 6px;
    }

    .vacancies-wrap .vacancies-filter-address > div:last-child{
        margin-left: 6px;
    }

    .vacancies-page-filter-container {
        margin-left: 0;
        margin-right: 0;
        border-radius: 10px;
    }

    .vacancies-page-multiple {
        -webkit-animation: rising .2s;
        animation: rising .2s;
    }
    .mobile-container-page-sort {
        display: none;
    }
}

.vacancies-page-filter-container div.react-select__control {
    height: auto;
    min-height: 40px;
}

.vacancies-page-filter-container div.react-select-menu-is-close,
.vacancies-page-filter-container div.react-select-menu-is-open {
    margin-bottom: 10px;
}

.vacancies-page-filter-container div.react-select__value-container--is-multi.react-select__value-container--has-value {
    padding-left: 10px;
}

.vacancies-page-filter-container div.react-select__multi-value {
    padding: 7px 7px 7px 12px;
    margin: 5px;
    border-radius: 15px;
    min-height: 30px;
    background-color: #dff5f1;
    font-size: 100%;
}

.vacancies-page-filter-container div.react-select__multi-value__label {
    color: #02a88d;
    padding: 0;
}

.vacancies-page-filter-container div.react-select__multi-value__remove {
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
    color: #02a88d;
}

.vacancies-page-filter-container div.react-select__multi-value__remove:hover {
    color: #354169;
    background-color: #dff5f1;
}

.vacancies-page-sort div.react-select-menu-is-close,
.vacancies-page-sort div.react-select-menu-is-open {
    display: inline-block;
    width: 160px;
    margin: 10px 0 10px 30px;
}

.vacancies-page-sort.archive{
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.vacancies-page-sort .checkbox-container {
    margin-bottom: 0;
}

.mobile-container-page-sort .checkbox-container {
    margin: 0;
}

.vacancies-page-sort {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.vacancies-page-sort .checkbox-mark, .container-title-checkbox .checkbox-mark, .response-item-name .checkbox-mark, .mobile-container-page-sort .checkbox-mark {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(80,80,80, .3), 0 2px 2px rgba(164,164,164, .3);
}

.vacancies-page-sort-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
}

.vacancies-page-multiple .button-add-item {
    margin-right: 10px;
}

.add-vacancy-button {
    margin-top: 10px;
}

.import-new-vacancy-item {
    font-size: 17px;
    margin-bottom: 5px;
}

.import-old-vacancy-item {
    font-size: 17px;
    color: #02a88d;
    margin-bottom: 5px;
}

.import-old-vacancy-item :hover {
    color: #02a88d;
}

.import-vacancies-wrapper .button:nth-child(2) {
    margin-left: 15px;
 }

.import-vacancies-wrapper .button {
    font-size: 12px;
    height: 35px;
}


.import-hh-vacancies-drop-list > button {
    color: #02a88d;
    font-size: 18px;
}

.import-vacancies-wrapper .button-add-item span {
    margin: 0;
    font-size: 14px;
}

@media screen and (min-width: 992px) {
    .vacancies-page-multiple .button-add-item {
        margin-right: 15px;
    }
    .add-vacancy-button {
        margin-top: 0;
    }
}

@media screen and (max-width: 968px) and (min-width: 768px) {
    .vacancies-page-sort:not(.archive) .vacancies-page-sort-container{
        width: 100%;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
}

@media screen and (max-width: 768px) {
    .import-new-vacancy-item,
    .import-old-vacancy-item {
        font-size: 14px;
    }
    .vacancies-page-sort-container {
        margin-left: 0;
        width: 100%;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .vacancies-page-sort.archive .vacancies-page-sort-container {
        width: 180px;
        margin-left: auto;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }
}
.vacancies-page-multiple {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px;
}

.vacancies-page-multiple .vacancy-item-description-elem {
    margin-left: 8px;
}

.button-multiple, .multiple-select, .mobile-button-multiple-menu, .mobile-container-page-sort {
    -webkit-animation: rising .2s;
    animation: rising .2s;
}

.multiple-select {
    margin-right: 5px;
    margin-left: 0;
}


@-webkit-keyframes rising {
    0% {
        -webkit-transform: scale(1.0, 0);
        transform: scale(1.0, 0);
    }
    100% {
        -webkit-transform: scale(1.0, 1.0);
        transform: scale(1.0, 1.0);
    }
}


@keyframes rising {
    0% {
        -webkit-transform: scale(1.0, 0);
        transform: scale(1.0, 0);
    }
    100% {
        -webkit-transform: scale(1.0, 1.0);
        transform: scale(1.0, 1.0);
    }
}

.vacancies-icons-wrap-button, .icons-wrap-button {
    margin-bottom: 10px;
    margin-top: 10px;
}

.button-multiple {
    margin-top: 3px;
}

.mobile-button-multiple-menu {
    display: none;
}

@media screen and (max-width: 576px) {
    .vacancies-wrap .main-page-title {
        width: 100%;
    }
    .vacancies-icons-wrap-button, .icons-wrap-button {
        margin-bottom: 20px;
        margin-top: 13px;
    }
    .vacancies-page-multiple {
        display: none;
    }
    .button-multiple {
        margin-right: 10px;
        margin-top: -5px;
    }
    .multiple-select {
        margin-left: 0;
    }

    .mobile-button-multiple-menu {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 16px;
        margin-top: 11px;
        position: relative;
        margin-left: auto;
    }
    .mobile-button-multiple-menu .vacancy-item-description-elem {
        font-size: 14px;
        height: 100%;
        display: inline-block;
    }
    .mobile-button-multiple-menu .vacancy-item-description-elem::first-letter {
        text-transform: uppercase !important;
    }
    .mobile-button-multiple-menu .item-icon {
        padding-top: 2px;
    }
    .mobile-multiple-menu {
        position: absolute;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        z-index: 1;
        right: 0;
        top: 20px;
        background-color: white;
    }
    .mobile-multiple-menu .item-icon {
        display: none;
    }
    .mobile-container-page-sort {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
    }

    .mobile-container-page-sort .react-select-menu-is-close{
        margin-left: auto;
    }

    .mobile-container-page-sort.archive .react-select-menu-is-close,
    .mobile-container-page-sort .react-select-menu-is-close{
        margin-right: auto;
        margin-left: 0;
    }

    @media screen and (min-width: 424px) and (max-width: 576px){
        .mobile-container-page-sort .react-select-menu-is-close{
            margin-right: 0;
            margin-left: auto;
        }
    }

    .mobile-container-page-sort div.react-select-menu-is-close,
    .mobile-container-page-sort div.react-select-menu-is-open {
        width: 150px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .mobile-container-page-sort .checkbox-container {
        word-wrap: break-word;
        max-width: 50%;
    }
    .mobile-container-page-sort .checkbox-mark {
        top: 50%;
        margin-top: -8px;
    }
    .mobile-multiple-menu button {
        display: block;
        margin: 20px 10px;
    }
}

@media screen and (max-width: 470px) {
    .import-vacancies-import-button-wrapper {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-bottom: 15px;
    }

    .import-vacancies-wrapper .button:nth-child(2) {
        margin-left: 0;
     }
}

@media screen and (max-width: 430px) {
    .vacancies-page-sort div.react-select-menu-is-close,
    .vacancies-page-sort div.react-select-menu-is-open {
        margin-left: 0;
    }
}

@media screen and (max-width: 520px) {
    .vacancies-page-sort .checkbox-container {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 576px) {
    .vacancies-page-sort {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: start;
                align-items: start;
    }

    .vacancies-page-selected-actions {
        width: 100%;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
}





.payment-form {
    padding: 0 15px 40px;
    border-bottom: 1px solid #dfe9e9;
}

.payment-info {
    padding: 35px 15px 30px;
    background-color: #f3f7f7;
}

.payment-info-top {
    margin-bottom: 40px;
}

.payment-info-bottom {
    display: -webkit-flex;
    display: flex;
}

.payment-period > label {
    color: #b7beca;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
    margin-bottom: 14px;
}

.payment-email {
    margin-bottom: 35px;
}

.payment-company {
    margin-bottom: 35px;
}

.payment-period {
    margin-bottom: 45px;
}

.payment-text {
    color: #354169;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.promo-code-wrap .form-control {
    max-width: 318px;
    height: 40px;
    line-height: 40px;
    color: #b7beca;
    font-size: 14px;
    margin-bottom: 15px;
}

.payment-email label,
.promo-code-wrap .render-field-container label {
    color: #b7beca;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
    margin-bottom: 14px;
}

.promo-code-wrap .form-control::-webkit-input-placeholder {
    color: #b7beca;
}
.promo-code-wrap .form-control::-moz-placeholder {
    color: #b7beca;
}
.promo-code-wrap .form-control:-ms-input-placeholder {
    color: #b7beca;
}
.promo-code-wrap .form-control:-moz-placeholder {
    color: #b7beca;
}

.payment-form form {
    margin-bottom: 20px;
}

.promo-code-wrap .form-group {
    margin-bottom: 0;
}

.payment-text.text-margin {
    margin-bottom: 45px;
}

.promo-code-wrap .button {
    height: 40px;
    padding: 0 20px;
    font-size: 12px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.payment-info-text {
    color: #354169;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 35px;
}

.payment-info-text-email {
    color: #354169;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.35px;
}

.payment-info-list {
    padding: 0;
    list-style: none;
    margin-bottom: 40px;
}

.payment-info-list-item {
    position: relative;
    padding-left: 20px;
    margin-bottom: 25px;
}

.payment-info-list-item:last-child {
    margin-bottom: 0;
}

.payment-info-list-item:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 5px;
    height: 2px;
    background-color: #26b9a1;
}

.payment-info-list-item-title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.4px;
    color: #354169;
}

.payment-info-list-item-text {
    font-size: 12px;
    color: #a0abbd;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.payment-info-requisites {
    display: block;
    color: #42bca1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.35px;
    cursor: pointer;
}

.payment-info-requisites:hover {
    color: #047b63;
    transition: color 0.3s ease;
}

.payment-info-bottom .icon-wrap {
    margin: 3px 17px 0 0;
}

.payment-info-bottom .icon-wrap .item-icon {
    color: #42bca1;
}

.modal-payment.modal-dialog {
    max-width: 390px;
}

.modal-payment.modal-dialog .modal-title {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: 1.2;
}

.modal-payment.modal-dialog .modal-header {
    padding: 40px 40px 45px;
}

.modal-payment.modal-dialog .modal-body {
    padding: 0 40px 40px;
}

.modal-payment.modal-dialog .modal-body p {
    color: #354169;
    font-size: 16px;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .payment-period {
        margin-bottom: 40px;
    }
    .payment-form {
        max-width: 50%;
        border-bottom: none;
        padding: 15px 15px 0 0;
    }
    .payment-info {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        max-width: 360px;
        border-radius: 10px;
        border: 1px solid #dbe0e8;
        background-color: #f3f7f7;
    }
    .payment-info-top {
        margin-bottom: 0;
    }
    .payment-info-requisites {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 992px) {
    .payment-form {
        width: 100%;
        max-width: 460px;
        padding-left: 15px;
    }
    .payment-info {
        padding: 35px 15px 30px 30px;
    }
    .payment-text a.light-green {
        display: block;
    }
    .promo-code-wrap > form {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-end;
                align-items: flex-end;
    }
    .promo-code-wrap .form-control {
        margin-bottom: 0;
    }
    .promo-code-wrap .button {
        margin-left: 10px;
    }
    .payment-title {
        padding: 0 45px;
    }
}

@media screen and (min-width: 992px) {
    .payment-form {
        max-width: 500px;
    }
}
.template-editing {
    max-width: 930px;
}

.field-sets-wrap .field-sets-item {
    padding: 23px 0;
}

.field-sets-item-link {
    min-width: 0;
    font-size: 18px;
    color: #26b9a1;
    padding-right: 30px;
    word-wrap: break-word;
}

.template-editing-title {
    font-size: 24px;
    color: #354169;
    font-weight: 400;
    letter-spacing: 0.6px;
    margin-bottom: 45px;
}

.template-editing-title span {
    font-weight: 700;
}

/* render-actions-wrap */

.render-actions-wrap {
    margin-bottom: 65px;
}

.template-editing-form-heading {
    padding-bottom: 17px;
}

.render-actions {
    margin-bottom: 22px;
}

.render-actions-setup-container {
    position: relative;
    padding: 22px 0 45px;
}

.render-actions-setup-container:first-child {
    padding-top: 45px;
}

.render-actions-setup-container:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -15px;
    right: -15px;
    height: 1px;
    background-color: #ced3d9;
}

.render-actions-setup-container:last-child:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: -15px;
    right: -15px;
    height: 1px;
    background-color: #ced3d9;
}

.render-actions-container {
    position: relative;
    padding: 30px 0;
    border-bottom: 1px solid #f1f3f7;
}

.render-actions-container:first-child {
    border-top: 1px solid #f1f3f7;
}

.render-actions-container:last-child {
    border-bottom: none;
}

.render-actions-container .form-group {
    padding-right: 35px;
}

.render-actions-container .form-group:last-of-type {
    margin-bottom: 0;
}

.render-actions-title {
    display: block;
    color: #354169;
    font-size: 16px;
    margin-bottom: 5px;
    padding-left: 23px;
}

.render-actions-setup-header .render-creatable-select-container {
    padding-top: 30px;
}

.render-actions-setup-container:first-child .render-actions-setup-header .render-creatable-select-container {
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 30px;
}

.render-actions-delete {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    margin-top: 30px;
    right: 5px;
}

.render-actions-change {
    display: -webkit-flex;
    display: flex;
    -webkit-order: 0;
            order: 0;
    position: absolute;
    top: 20px;
    left: calc(50% - 85px);
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

@media screen and (max-width: 1200px) {
    .render-actions-change {
        left: 337px;
    }
}

@media screen and (max-width: 991px) {
    .render-actions-change {
        top: -15px;
        left: 45%;
        background: white;
        padding: 7px;
    }
    .render-actions-delete {
        top: -10px;
    }
    .initial-actions {
        padding-right: 30px;
    }
    .render-field-item {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start !important;
                align-items: flex-start !important;
    }
    .render-field-item-bottom {
        margin-top: 12px;
        width: 445px !important;
    }
}

@media screen and (max-width: 768px) {
    .render-field-item-bottom {
        width: 100% !important;
    }

}

.render-creatable-select-container {
    width: 100%;
}

.edit-fields-sets {
    max-width: 780px;
}

.edit-fields-disable-edit-text {
    font-size: 18px;
    color: #354169;
    letter-spacing: 0.45px;
}

.render-field-head {
    max-width: 450px;
}

/* create-field-form */

.create-new-field {
    margin-bottom: 35px;
}

.create-field-form .form-group {
    margin-bottom: 0;
}

.create-field-form-type-select {
    padding: 20px 0 35px;
}

.render-field-item-list {
    margin-bottom: 65px;
}

.render-field-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 35px 0 30px;
    border-top: 1px solid #f1f3f7;
}

.render-field-item .form-group {
    margin-bottom: 0;
}

.render-field-item-select {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.render-field-item-num {
    color: #b7beca;
    font-size: 16px;
    margin-right: 7px;
}

.render-field-item-bottom {
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.render-field-service-only-field-wrapper .item-icon {
    font-size: 14px;
    margin-top: 2px;
}

.render-field-item-bottom label {
    margin-bottom: 0;
}

.render-field-item .item-icon {
    display: block;
    margin-left: auto;
    margin-right: 13px;
}

.render-field-item .form-group label {
    display: none;
}

/* render-checkbox-container */

.render-checkbox-container {
    margin-left: 25px;
}

@media screen and (min-width: 576px) {
    .render-actions-setup-container:before,
    .render-actions-setup-container:last-child:after {
        display: none;
    }

    .render-actions-setup-container {
        border-radius: 10px;
        border: 1px solid #ced3d9;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }

}

@media screen and (min-width: 768px) {
    .field-sets-item-link {
        font-size: 20px;
    }
    .field-sets-wrap .field-sets-item {
        padding: 28px 0;
    }
    .render-field-item {
        -webkit-flex-wrap: unset;
                flex-wrap: unset;
        -webkit-align-items: center;
                align-items: center;
        border: none;
        padding: 25px 0 0;
    }
    .render-field-item-select {
        margin-bottom: 0;
    }
    .create-element-container {
        width: 100%;
    }
    .create-field-form-new-container{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .create-field-form {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-end;
                align-items: flex-end;
        -webkit-justify-content: space-around;
                justify-content: space-around;
    }
    .create-field-form > div {
        width: 250px;
    }
    .create-field-form .create-field-form-type-select {
        margin-left: 13px;
    }
    .create-field-form-type-select {
        padding: 0;
    }
    .render-field-item-select {
        max-width: 445px;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
    }
}

@media screen and (min-width: 992px) {
    .template-editing-title {
        max-width: 460px;
    }
    .template-editing .template-editing-title{
        padding-left: 62px;
    }
    .template-editing-form-heading {
        max-width: 460px;
        padding-left: 40px;
    }
    .render-actions-container {
        border-bottom: none;
    }
    .render-actions-setup-header .render-creatable-select-container {
        max-width: 335px;
        margin-bottom: 0;
        padding-top: 20px;
    }
    .render-actions-container .form-group {
        max-width: 335px;
        padding-right: 0;
        width: 100%;
    }
    .render-actions-setup-container:first-child .render-actions-setup-header .render-creatable-select-container {
        padding-bottom: 0;
    }
    .render-actions-container .form-group:first-child {
        margin-right: 20px;
    }
    .render-actions-delete {
        position: static;
        margin-left: 40px;
    }
    .render-actions-container {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }
    .render-actions-setup-container:first-child {
        padding-top: 45px;
    }
    .render-actions-setup-container {
        padding: 45px 40px;
    }
    .render-actions-container {
        padding: 35px 0 0;
    }
    .render-actions-container .form-group:first-child {
        margin-bottom: 0;
    }
    .render-actions-container:first-child {
        border-top: none;
    }
    .render-actions-container:last-child {
        padding-bottom: 40px;
    }
    .render-field-item-num {
        margin-right: 30px;
    }
    .render-checkbox-container {
        margin-left: 35px;
    }
    .create-field-form > div {
        width: 280px;
    }
}

.entities-edit-chevron-icon:not(:hover) {
    margin: 0 10px;
    font-size: 10px;
    color: #02a88d;
}

.entities-edit-chevron-container:hover .entities-edit-chevron-icon {
    color: #047b63;
    margin: 0 10px;
    font-size: 10px;
    transition: color 0.3s ease;
}

.entities-edit-chevron-container {
    cursor: pointer;
    margin-left: 15px;
    margin-top: 10px;
}

.entities-container-edit {
    list-style: none;
    margin: 0;
    padding: 0;
}

.entities-container-edit .create-element-container,
.entities-container-edit .create-field-form-new-container {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}
.entities-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
}

.width-half{
    width: 50%;
}
.margin-low{
    margin: 15px;
}

.margin-low-vertical{
    margin-top: 15px;
    margin-bottom: 15px;
}
.hiring-process-item-container {
    padding: 10px;
    font-size: 18px;
}
.footer {
    margin-top: 30px;
    padding: 20px 12%;
    font-size: 20px;
    height: 80px;
    color: #fff;
    background-color: #354169;
}

.footer-text {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #fff;
    margin-bottom: 6px;
}

.footer-email {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #b8c8e7;
}

.footer-email span {
    color: #14ccae;
}

@media screen and (min-width: 1600px) {
    .footer {
        padding: 20px 18%;
    }
}
a {
    color: #06b291;
}

.nav-item >.nav-link{
    color: #b7beca;
}

.nav-item > .nav-link.active {
    color: #06b291 !important;
}

a:hover {
    color: #047b63;
    text-decoration: none;
    transition: color 0.3s ease;
    cursor: pointer;
}

a:focus {
    outline: none;
}

.link {
    display: inline-block;
    font-size: 14px;
}

.return-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 45px;
}

.return-link__arrow {
    width: 8px;
    height: 8px;
    border-top: 1px solid #06b291;
    border-left: 1px solid #06b291;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin-right: 25px;
    transition: border-color 1.3s ease;
}

.return-link__text {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
}

.return-link:hover .return-link__arrow {
    border-color: #047b63;
}

@media screen and (min-width: 1300px) {
    .return-link {
        margin-left: -30px;
    }
}
.main-page-title {
    color: #b7beca;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    word-wrap: break-word;
}

h3 {
    color: #354169;
    font-size: 24px;
    font-weight: 700;
}

h4 {
    color: #354169;
    font-size: 18px;
    letter-spacing: 0.45px;
    margin-bottom: 0;
    font-weight: normal;
}

.title {
    color: #354169;
    font-size: 24px;
    font-weight: 700;
    word-wrap: break-word;
}

.item-text {
    color: #354169;
    font-size: 18px;
    word-wrap: break-word;
}
.item-text_color_grey {
    color: #9ba2b0;
}


@media screen and (min-width: 768px) {
    .main-page-title {
        font-size: 36px;
    }
}

/* page-header-container  */

.page-header-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 25px;
}
.page-header-container__title {
    margin-bottom: 25px;
}
.page-header-container__filter {
    width: 100%;
}

@media screen and (min-width: 576px) {
    .page-header-container {
        padding: 0;
        margin-bottom: 35px;
    }
    .page-header-container__title {
        margin-bottom: 0;
    }
    .page-header-container__filter {
        max-width: 291px;
    }
}
@media screen and (max-width: 576px) {
    .page-header-container {
        margin-bottom: 10px;
    }
    .responses-wrap .page-header-container {
        margin-bottom: 40px;
    }
}

/* content-container */

.content-container-list__item {
    margin-bottom: 30px;
}
.content-container-list__item:last-child {
    margin-bottom: 0;
}

.content-container {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    padding: 35px 15px;
    background-color: #fff;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}
.content-container_padding_sm {
    padding: 0 15px;
}
.content-container_for-page-tariffs {
    padding: 50px 15px;
    margin-bottom: 40px;
}
.content-container_for-page-payment {
    padding: 40px 0 0;
}
.content-container__item {
    padding: 30px 0 25px;
    border-bottom: 1px solid #dfe9e9;
}
.content-container__item:last-child {
    border-bottom: none;
}
.content-container__empty {
    padding: 30px 0 25px;
    font-size: 16px;
    color: #354169;
}
.content-container__flex-between-center {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.content-container__top-line {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 5px;
    background-color: #fad16f;
}
.content-container__top-line_color_red {
    background-color: #f9764e;
}

@media screen and (min-width: 576px) {
    .content-container {
        margin-left: 0;
        margin-right: 0;
        border-radius: 10px;
    }
    .content-container_for-page-tariffs {
        padding: 50px;
    }
    .content-container-list__item {
        margin-bottom: 30px;
    }
    .content-container__top-line {
        right: 50px;
        left: 50px;
    }
}
@media screen and (min-width: 768px) {
    .content-container_for-page-tariffs {
        width: 47%;
        margin-bottom: 0;
    }
    .content-container_for-page-payment {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 30px 15px;
    }
    .content-container__item {
        padding: 37px 0 32px;
    }
}
@media screen and (min-width: 992px) {
    .content-container {
        padding: 45px 50px;
    }
    .content-container_padding_sm {
        padding: 13px 50px 23px;
    }
    .content-container_for-page-payment {
        padding: 30px;
    }
    .content-container__item {
        padding: 37px 0 35px;
    }
}

/* list(tariffs) */

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.list__item {
    position: relative;
    color: #354169;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 20px;
}
.list__item:last-child {
    margin-bottom: 0;
}
.list__item:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 5px;
    height: 2px;
    background-color: #26b9a1;
}

/* frame-text  */

.frame-text {
    padding: 20px;
    border: 1px solid #dbe3e3;
    border-radius: 10px;
}

.frame-text > p {
    margin-bottom: 0;
}

/* errors */

.form-error {
    color: #d86155;
    padding: 5px 0;
}

@media screen and (min-width: 768px) {
    .form-error {
        font-size: 16px;
    }
}
/* custom buttons */

button {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
}
button:focus {
    outline: none !important;
}

.archive-vacancy-button {
    margin-top: 14px;
}

.button {
    display: inline-block;
    height: 45px;
    padding: 0 30px;
    border-radius: 22px;
    color: #ffffff;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    background-color: #14ccae;
    transition: all 0.15s;
}
.button:hover {
    background-color: #02a88d;
    border-color: #02a88d;
}
.button[disabled] {
    background-color: #e8ebf5;
    color: #a1a9c2;
}
.button_color_dark-blue {
    background-color: #354169;
}
.button_color_dark-blue:hover {
    background-color: #182346;
}
.button_size_sm {
    height: 35px;
    font-size: 12px;
    padding: 0 25px;
    letter-spacing: 1.38px;
}
.button_plus {
    width: 35px;
    height: 35px;
    padding: 0 0 2px;
    line-height: unset;
}
.button_plus .button-icon {
    font-size: 24px;
    padding-left: 2px;
}
.button_plus .button-text {
    display: none;
}

.icon_crown {
    transition: .2s;
    margin: auto;
}

.icon_crown:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.button-add-item{
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #0bb79b;
    background: transparent;
    border: 0;
    padding: 0;
}
.button-add-item span {
    margin-right: 7px;
}
.button-add-item:hover {
    color: #047b63;
    transition: color 0.3s ease;
}


.button-tag-item {
    max-width: 100%;
    border: 1px solid #354169;
    background-color: #fff;
    border-radius: 3px 3px 3px 3px;
    color: #354169;
    display: inline-block;
    line-height: 1;
    text-align: left;
    padding: 7px 10px;
    word-wrap: break-word;
    transition: all 0.15s;
}
.button-tag-item:hover {
    color: #fff;
    background-color: #354169;
}


.button-frame {
    width: 100%;
    background-color: #fff;
    color: #0bb79b;
    font-size: 18px;
    border-radius: 10px;
    border: 1px solid rgba(11, 183, 155, 0.5);
    letter-spacing: 0.7px;
    height: 57px;
    line-height: 57px;
}
.button-frame:hover {
    border: 1px solid rgba(11, 183, 155, 1);
    background-color: #fff;
}


.button-circle {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 28px;
    height: 28px;
    background-color: #eff4f4;
    border-radius: 50%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}
.button-circle svg {
    font-size: 12px;
    color: #354169;
}
.button-circle_color_light-green {
    background-color: #14ccae;
    transition-duration: 500ms;
}
.button-circle_color_gray {
    background-color: #eff4f4;
    cursor: default;
    opacity: 0.3;
    border: 1px solid #354169;
    transition-duration: 500ms;
}
.button-circle_color_dark-blue {
    background-color: #354169;
}
.button-circle_color_light-green svg,
.button-circle_color_dark-blue svg {
    color: #fff;
}

.button-dash {
    display: inline-block;
    height: 35px;
    padding: 0 22px;
    border-radius: 22px;
    color: #0aa78d;
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    border: 1px dashed #0aa78d;
}
.button-dash__icon {
    display: inline-block;
    margin-right: 10px;
}

/* buttons containers */

.create-field-form > .buttons-circle-container{
    margin-left: 20px;
    width: 100px;
}

@media screen and (max-width: 767px){
    .create-field-form > .buttons-circle-container{
        width: auto;
    }
}

@media screen and (max-width: 767px) {
    .buttons-circle-container {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }
}

.buttons-circle-container {
    display: -webkit-flex;
    display: flex;
}
.buttons-circle-container .button-circle {
    margin-right: 10px;
}
.buttons-circle-container .button-circle:last-child {
    margin-right: 0;
}


.buttons-container .button {
    width: 100%;
    margin: 10px 15px 10px 0;
}
.buttons-container .button:last-of-type {
    margin-right: 0;
}

@media screen and (min-width: 576px) {
    .buttons-container .button {
        width: unset;
    }
    .button-select-all {
        -webkit-order: -1;
                order: -1;
    }
}

@media screen and (max-width: 576px) {
    .button-select-all {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .button_plus {
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        width: unset;
        padding: 0 20px;
    }
    .button_plus .button-icon {
        font-size: 18px;
        padding-bottom: 2px;
    }
    .button_plus .button-text {
        display: inline;
        font-size: 12px;
        margin-left: 10px;
        letter-spacing: 1.38px;
    }


    .button-add-item {
        font-size: 16px;
    }

    .button-frame {
        height: 70px;
        line-height: 70px;
    }


    .button-circle {
        width: 36px;
        height: 36px;
    }
    .button-circle svg {
        font-size: 15px;
    }
}

.DateRangePicker__CalendarSelection--is-pending {
    background-color: #0bb79b !important;
}

.DateRangePicker__CalendarSelection {
    background-color: #0bb79b !important;
    border-color: #0bb79b !important;
}

.DateRangePicker__CalendarHighlight--single {
    background-color: #fff;
    border: 1px solid #0bb79b !important;
}

.DateRangePicker__MonthDates > table {
    font-family: 'Ubuntu', sans-serif;
}
.item-icon {
    cursor: pointer;
    font-size: 16px;
    color: #c3c9d0;
}


/* TOOLTIP */

.tooltip-custom {
    display: inline-block;
    padding: 0 10px;
}

.tooltip-import-hh-vacancies {
    padding: 0;
}

.tooltip-custom__window {
    background-color: #2e354c;
    opacity: 1;
    border-radius: 7px;
    font-family: 'Ubuntu', sans-serif;
    color: #eff4f4;
}
.tooltip-custom__window_default {
    max-width: 310px;
    padding: 10px 12px;
}

.tooltip-custom__window_sm {
    max-width: 205px;
    padding: 5px 5px;
}

.tooltip-custom__arrow_top:before {
    border-top-color: #2e354c !important;
}

.tooltip-custom__arrow_right:before {
    border-right-color: #2e354c !important;
}

.main-page-title__tooltip {
    font-size: 17px;
}

@media screen and (min-width: 768px) {
    .main-page-title__tooltip {
        font-size: 24px;
    }
}

.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    color: #354169;
    font-size: 14px;
    padding-left: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-hidden {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border-radius: 4px;
    background-color: #eee;
}

.checkbox-mark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkbox-mark:after {
    display: block;
}

.checkbox-container .checkbox-mark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 8px;
    border: solid #354169;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container-title-checkbox {
    display: -webkit-flex;
    display: flex;
}

.container-title-checkbox .checkbox-container{
    margin-bottom: 31px;
}

.container-title-checkbox .checkbox-mark, .response-item-name .checkbox-mark {
    top: 38%;
}
.radio-container {
    display: -webkit-flex;
    display: flex;
}

.radio {
    display: block;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #354169;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}

.radio-container__item {
    margin-right: 40px;
}

.radio-container__item:last-child {
    margin-right: 0;
}

.radio-hidden {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #e1eaea;
    border-radius: 50%;
}

.radio-mark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-hidden:checked ~ .radio-mark:after {
    display: block;
}

.radio-container .radio-mark:after {
    top: 5px;
    left: 5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #354169;
}

.form-group {
    margin-bottom: 24px;
}

.form-group label {
    color: #a3a4a9;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
    margin-bottom: 13px;
}

.date-response-filter {
    background-color: white !important;
    margin: 7px;
    width: auto;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}

.date-filter-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-left: 20px;
}

.date-filter-container-decorator {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-left: 20px;
    border-radius: 30px 30px 30px 30px;

}

.date-filter-container-decorator input {
    margin: 0;
    box-shadow: none;
    width: 85px;
    padding-left: 0;
    padding-right: 0;
}

.react-datepicker {
    font-family: inherit !important;
    background-color: #FFFFFF !important;
}
.react-datepicker__day--selected {
    background-color: #06b291 !important;
}

@media screen and (max-width: 577px) {
    .date-filter-container-decorator input {
        padding-left: 10px;
        padding-right: 10px;
        width: 100px;
    }
    .react-datepicker__input-container {
        padding-left: 0;
    }
}

@media screen and (min-width: 578px) {
    .react-datepicker__input-container {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 767px) {
    .date-filter-container{
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        padding-top: 10px;
    }
    .date-filter-container-decorator{
        margin-bottom: 20px;
        margin-left: 0px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 575px) {
    .date-filter-container-decorator {
        background-color: transparent;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .date-filter-container-decorator .date-filter-dash {
        display: none;
    }
    .date-filter-container-decorator input:last-child {
        margin-top: 15px;   
    }
}


.form-control {
    border-radius: 23px;
    background-color: #eff4f4;
    font-size: 16px;
    padding: 8px 22px;
    height: unset;
    color: #354169;
    border: none;
}

.form-control::-webkit-input-placeholder {
    color: #787c7d;
}
.form-control::-moz-placeholder {
    color: #787c7d;
}
.form-control:-ms-input-placeholder {
    color: #787c7d;
}
.form-control:-moz-placeholder {
    color: #787c7d;
}

.form-group .render-field-error {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 5px 21px;
    background-color: #d86155;
    border-radius: 8px;
}

.render-field-item .render-field-error {
    top: -30px;
}

.form-file-container .render-field-error {
    right: auto;
    top: -10px;
}

.form-group .render-field-error .error-text {
    display: block;
    position: relative;
    color: #eff4f4;
    font-size: 12px;
    letter-spacing: 0.3px;
    z-index: 2;
}

.form-group .render-field-error .error-text:before {
    display: block;
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #d86155;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(135deg);
            transform: translateX(-50%) rotate(135deg);
    bottom: -9px;
    z-index: 1;
}

/* correct */

.form-control:focus {
    color: #354169;
    border: none;
    box-shadow: none;
    background-color: #eff4f4;
}

.template-editing-form-heading .form-group label,
.render-actions-wrap .form-group label,
.create-field-form label,
.render-field-head label {
    padding-left: 23px;
}

/* selects */

div.react-select__control {
    height: 40px;
    border-radius: 23px;
    background-color: #eff4f4;
    font-size: 16px;
    border: none;
    transition: unset;
}

.react-select-menu-is-open div.react-select__control {
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.react-select__control.react-select__control--is-focused {
    box-shadow: none;
}

div.react-select__value-container {
    padding: 0 20px;
}

div.react-select__input,
div.react-select__single-value {
    color: #354169;
}

div.react-select__menu {
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    margin: 0;
    box-shadow: none;
    z-index: 30;
}

div.react-select__menu-list {
    padding: 0;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    border: 1px solid #b9cece;
    border-top: none;
}

div.react-select__option {
    padding-left: 22px;
    color: #8e97b2;
    font-size: 14px;
    background-color: #fff !important;
    word-wrap: break-word;
}

div.react-select__option:last-child {
    padding-bottom: 13px;
}

div.react-select__option--is-focused {
    background-color: #e5eaed !important;
}

span.react-select__indicator-separator {
    display: none;
}

div.react-select__dropdown-indicator {
    padding-right: 18px;
    color: #354169;
}

div.react-select__dropdown-indicator:hover {
    color: #354169;
}

div.react-select__dropdown-indicator svg {
    width: 17px;
    height: 17px;
    transition: -webkit-transform ease 0.3s;
    transition: transform ease 0.3s;
    transition: transform ease 0.3s, -webkit-transform ease 0.3s;
}

.react-select-menu-is-open div.react-select__control .react-select__dropdown-indicator svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.react-select__input > input {
    opacity: 1 !important;
}

.react-select-menu-is-close div.react-select__control {
    border: 1px solid transparent;
}

.react-select-menu-is-open div.react-select__control {
    background-color: #fff;
    border: 1px solid #b9cece;
    border-bottom: 1px solid transparent;
}

.react-select__control .react-select__placeholder {
    color: #787c7d;
}

.react-select__control.react-select__control--is-disabled div.react-select__dropdown-indicator,
.react-select__control.react-select__control--is-disabled .react-select__placeholder,
.react-select__control.react-select__control--is-disabled .react-select__single-value--is-disabled {
    color: #9d9d9f;
}

/* textarea */

textarea {
    resize: none;
}

.modal {
    text-align: center;
}

.modal-dialog {
    display: inline-table;
    margin: 130px auto;
    padding: 0 20px;
    text-align: left;
    text-align: initial;
}

.modal-hh-import-wrapper {
    max-width: 550px;
    width: 100%;
    max-height: 200px;
}

.modal-content {
    border-radius: 10px;
}

.modal-title {
    color: #354169;
    font-size: 24px;
    font-weight: 700;
}

.modal-header {
    padding: 50px 15px 40px;
    border-bottom: none;
}

.modal-body {
    padding: 0 15px;
    border-bottom: none;
}

.modal-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: unset;
            align-items: unset;
    padding: 50px 15px;
    border-top: none;
}

.modal-window__footer {
    padding: 25px;
}

.modal-header .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    background-color: #14ccae;
    line-height: unset;
    padding: 0 0 2px;
    border-radius: 50%;
    opacity: 1;
    color: #fff;
    transition: background-color 0.3s;
}

.modal-header .close:hover,
.modal-header .close:not(:disabled):not(.disabled):hover{
    color: #fff;
    background-color: #02a88d;
    opacity: 1;
}

.modal-body {
    color: #aab3bd;
    font-size: 16px;
    font-weight: 400;
}

.modal-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.modal-flex .buttons-container {
    width: 100%;
}

.modal-flex .form-error {
    text-align: left;
}

@media screen and (min-width: 768px) {
    .modal-dialog {
        padding: 0;
    }
    .modal-header {
        padding: 50px 50px 40px;
    }
    .modal-body {
        padding: 0 50px;
    }
    .modal-footer {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        padding: 50px;
    }
    .modal-flex .buttons-container {
        width: unset;
    }
    .modal-flex .form-error {
        text-align: right;
    }
    .modal-dialog {
        min-width: 500px;
    }
}

.tariff__title {
    margin-bottom: 35px;
}

.tariff__features-list {
    margin-bottom: 50px;
}

.tariff__subtitle {
    margin-bottom: 15px;
}

.tariff__payment-option-container {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 45px;
}

.tariff__payment-option {
    width: 50%;
    max-width: 180px;
}

.tariff__price-container {
    margin-bottom: 5px;
}

.tariff__price {
    display: inline-block;
    color: #354169;
    font-size: 24px;
    font-weight: 700;
    margin-right: 3px;
}

.tariff__currency {
    display: inline-block;
    font-size: 19px;
    color: #354169;
    font-weight: 700;
}

.tariff__payment-option-text {
    color: #9ba3b1;
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 10px;
}

.tariff__button {
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .tariffs-container {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
}
.widget-setup-page__title {
    font-size: 36px;
    margin-bottom: 40px;
}

.customization-list {
    padding: 35px 20px;
    background-color: #fff;
    margin-right: -15px;
    margin-left: -15px;
}

.customization-container {
    margin-bottom: 30px;
}

.customization-container:last-child {
    margin-bottom: 0;
}

.customization-container__title {
    margin-bottom: 30px;
}

.customization-container-settings-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.customization-item {
    padding: 10px 0 20px;
    width: 100%;
    max-width: 300px;
}

.customization-item__language-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 10px;
}

.customization-item__language-container .customization-item-title {
    margin: 0;
}

.customiztion-item__language-select {
    max-width: 150px;
    width: 100%;
    margin-left: 10px
}

.customization-item-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 7px 0;
}

.customization-item-title {
    font-size: 16px;
    color: #354169;
    margin-bottom: 7px;
}

.customization-item-description {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 10px;
}

.customization-item-description .item-icon {
    margin-right: 8px;
    cursor: auto;
    color: #b7beca;
}

.customization-item-description-text {
    color: #b7beca;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.customization-container-settings-container .size-selector-container {
    width: 70px;
}

.customization-container-settings-container div.react-select__control {
    min-height: 33px;
    height: 30px;
    border-radius: 15px;
}

.customization-container-settings-container div.react-select__value-container {
    padding: 0 0 0 12px;
}

.customization-container-settings-container div.react-select__dropdown-indicator {
    padding: 0 12px 0 0;
}

/* form */

.customization-form-input {
    max-width: 250px;
}

.customization-form-select {
    height: 35px;
    width: 130px;
}

.customization-form-button {
    margin: 10px 0;
}

.customization-form-checkbox-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

/* widget */

.customization-widget {
    padding: 25px 15px 30px;
}

.customization-widget-content {
    margin-right: -15px;
    margin-left: -15px;
    background-color: #fff;

}

@media screen and (min-width: 576px) {
    .customization-list,
    .customization-widget-content {
        margin: 0;
    }
}

@media screen and (min-width: 768px) {
    .customization-item {
        max-width: 250px;
    }
}

@media screen and (min-width: 992px) {
    .customization-widget {
        padding-top: 0;
    }
}

@media screen and (min-width: 1200px) {
    .customization-list {
        padding: 35px 20px 20px 20px;
    }
    .customization-setting,
    .customization-widget {
        padding: 0 15px;
    }
}

@media screen and (min-width: 1200px) {
    .widget-setup-page__title {
        font-size: 42px;
    }
}

.url-container-toggle {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.url-container-toggle-header {
    width: calc(100% - 48px);
}

.team-url-editor-container {
    display: -webkit-flex;
    display: flex;
    min-height: 40px;
    margin-bottom: 10px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
}

.team-url-editor-container .form-group {
    margin-bottom: 10px;
}

.team-url-editor-container-title {
    padding: 7px 30px 7px 0;
    font-weight: lighter;
    color: #354169;
}
.team-url-editor-container-description {
    color: #354169;
}

.customization-container-captcha-switch{
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.vacancy-item-switch-captcha{
    padding: 7px;
}
.profile-page__title {
    margin-bottom: 33px;
    text-align: center;
}

.profile-page h4 {
    margin-bottom: 30px;
}

.profile-heading {
    margin-bottom: 45px;
}

.profile-avatar {
    text-align: center;
    margin: 0 auto;
}

.profile-avatar-pic {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 15px;
}

.profile-avatar-file-input {
    display: none;
}

.profile-avatar-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-avatar-text {
    font-size: 14px;
    cursor: pointer;
}

.profile-page .form-group {
    margin-bottom: 26px;
}

.profile-info {
    padding-bottom: 47px;
}

.profile-data h4 {
    padding-top: 27px;
    border-top: 1px solid #e3eded;
}

.profile-data form {
    padding-bottom: 37px;
}

.profile-data form:last-of-type{
    padding-bottom: 0;
}

.profile-data label {
    display: none;
}

.profile-data .render-field-container {
    margin: 0 0 20px;
}

.profile-data .form-group .render-field-error {
    top: -30px;
}

@media screen and (min-width: 768px) {
    .profile-page__title {
        font-size: 30px;
    }
    .profile-page__title {
        width: 50%;
        text-align: left;
    }
    .profile-heading {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
    .profile-avatar {
        width: 50%;
    }
    .profile-data h4 {
        padding-top: 0;
        border-top: none;
    }
    .profile-data form {
        border-bottom: none;
    }
    .profile-info {
        padding-bottom: 0;
    }
}
/* vacancy-form */

.vacancy-form {
    max-width: 612px;
    padding-bottom: 45px;
}

.vacancy-form__field {
    margin-bottom: 35px;
}

.vacancy-form__field .form-group {
    margin-bottom: 0;
}

.vacancy-form__button-add-item {
    margin-bottom: 30px;
}

.vacancy-form__questions {
    padding:  30px 0 55px;
}

/* vacancy-questions */

.vacancy-questions__title {
    margin-bottom: 25px;
}

.vacancy-questions__field {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.vacancy-questions__delete-icon {
    padding: 0 0 12px 25px;
}

.vacancy-questions__field .form-group {
    margin-bottom: 0;
}

.vacancy-questions__description {
    padding: 20px 0;
}

.vacancy-form-logo {
    width: 170px;
    height: 170px;
    border-radius: 15%;
}

.vacancy-form-logo-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15%;

}

.pagination-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-top: 30px;
}

.pagination-custom {
    display: -webkit-flex;
    display: flex;
    padding: 0;
    margin: 0 0 20px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: #ffffff;
}

.pagination-custom a {
    display: block;
    width: 30px;
    text-align: center;
    font-size: 14px;
    padding: 10px 5px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #c1c7d1;
}

.pagination-custom li {
    list-style: none;
    cursor: pointer;
    padding: 0;
}

.pagination-custom li.active a {
    color: #06b291;
}

.pagination-custom a:hover {
    color: #354169;
}

.pagination-custom__arrow {
    font-size: 11px;
    color: #06b291;
}

.pagination-container__prev,
.pagination-container__next {
    position: relative;
}

.pagination-container__prev:before,
.pagination-container__next:before {
    display: block;
    content: '';
    position: absolute;
    top: 5px;
    right: 0;
    width: 1px;
    height: 25px;
    background-color: #dfe9e9;
}

.pagination-container__next:before {
    right: unset;
    left: 0;
}

.items-to-show {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.items-to-show__text {
    color: #a5aebd;
    font-size: 12px;
}

.items-to-show__select {
    margin-left: 20px;
}

.items-to-show__select .react-select__control {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    background-color: #fff;
}

.items-to-show__select.react-select-menu-is-close div.react-select__control--is-focused {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}

.items-to-show__select div.react-select__control {
    height: 35px;
    min-height: 35px;
}

.items-to-show__select div.react-select__value-container {
    padding: 0 15px 0;
}

.items-to-show__select div.react-select__single-value {
    font-size: 12px;
}

.items-to-show__select div.react-select__dropdown-indicator {
    padding-right: 14px;
}

.items-to-show__select div.react-select__dropdown-indicator svg {
    width: 12px;
    height: 12px;
}

.items-to-show__select div.react-select__option {
    padding-left: 20px;
    font-size: 12px;
}

@media screen and (min-width: 576px) {
    .pagination-custom a {
        width: 40px;
    }
}

@media screen and (min-width: 768px) {
    .pagination-container {
        -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .pagination-custom {
        margin-bottom: 0;
    }
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.2;
    font-family: 'Ubuntu', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    overflow-x: hidden;
}

.private-page-container {
    min-height: calc(100vh - 110px);
    padding: 115px 15px 45px;
}

.default-text {
    color: #354169;
}

.loader {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
}

.transparent_loader {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 40px;
}

/* Custom styles */

.login-error-message {
    padding-top: 35px;
    color: #d86155;
    font-size: 14px;
    letter-spacing: 0.35px;
}

.sign-up-error-message {
    margin: 10px 0;
    color: #e02730;
}

.customization-page-title {
    text-align: center;
}

.widget-customization-color {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 40px 0 20px;
}

.widget-customization-color-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.widget-customization-color-button-container > Button {
    margin: 0 5px;
}

.color-picker {
    position: relative;
    display: inline-block;
}

.color-picker-box {
    width: 20px;
    height: 20px;
    border: 1px solid #a9a9a9;
    cursor: pointer;
}

.picker-popover {
    position: absolute;
    z-index: 2;
}

.picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.edit-hiring-template-stages {
    margin: 20px 0;
}

.hiring-stage-item-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.hiring-stage-item-input {
    max-width: 500px;
    margin: 5px 20px 5px 0;
}

.render-field-input {
    margin-bottom: 5px;
}

.render-field-error {
    color: red;
}

.render-actions-setup-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.status-message-request {
    padding: 15px;
}

.status-message-success {
    padding: 15px;
    color: green;
}

.status-message-fail {
    padding: 15px;
    color: red;
}

.not-found-page-container {
    margin: 30px 0;
    font-size: 18px;
}

.error-message-container {
    margin: 10px 0;
    color: red;
}

.login-password-restore-link-container a {
    font-size: 12px;
}

.demo-widget-container {
    border: 1px solid #dcdcdc;
}

@media screen and (max-width: 768px) {
    .widget-color-picker {
        width: calc((100% - 20px) / 3);
    }
}

@media screen and (max-width: 600px) {
    .widget-color-picker {
        width: calc((100% - 20px) / 2);
    }
}

/* hide spin box */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
    color: #fff;
    outline: none;
    opacity: 1;
}

/* =>>> */

.section-padding-sm {
    padding: 45px 15;
}

.label {
    color: #a3a4a9;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
}

.light-green {
    color: #26b9a1;
}

.dark-blue {
    color: #354169;
}

/* success-block */

.success-block-title {
    font-size: 24px;
    color: #354169;
    font-weight: 400;
    letter-spacing: .6px;
    margin-bottom: 40px;
}

.success-block-link {
    font-size: 16px;
}

/* 3rd party styles */

.mce-fullscreen.mce-container {
    border-radius: 0 !important;
}

.mce-container {
    border-radius: 23px !important;
    background: #eff4f4 !important;
    box-shadow: none !important;
    border: none !important;
}

div.mce-edit-area {
    background: #eff4f4 !important;
    padding: 10px 20px !important;
    color: #354169 !important;
}

.mce-btn {
    border: none !important;
    background: #eff4f4 !important;
    border-radius: 10px !important;
    opacity: 0.5;
    transition: opacity 0.2s ease;
}

.mce-btn:hover {
    color: #354169 !important;
    opacity: 1 !important;
}

.mce-btn.mce-active i {
    color: #02a88d !important;
    opacity: 1 !important;
}

.mce-active .mce-txt {
    color: #354169 !important
}

.mce-btn-group:not(:first-child) {
    border: none !important;
}

.mce-btn.mce-disabled button:hover {
    cursor: not-allowed !important;
}

.mce-primary button {
    color: #595959 !important;
}

.mce-statusbar,
.mce-menubar,
.mce-menu-item-sep {
    display: none !important;
}

.mce-toolbar,
.mce-stack-layout-item,
.mce-top-part::before {
    box-shadow: none !important;
}

.mce-toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.mce-menu-item {
    background-color: #fff !important;
}

.mce-fixed-width span {
    width: 30px !important;
}

.mce-tinymce iframe {
    min-height: 200px;
}

.mce-menu-item.mce-active.mce-menu-item-normal .mce-text {
    color: #06b291 !important;
}

.Toastify__toast {
    border-radius: 5px;
    font-family: 'Ubuntu', sans-serif;
}

.Toastify__toast-body {
    padding: 5px;
    padding-left: 8px;
    margin: auto 0;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}

.Toastify__toast--success {
    background: lightseagreen;
}

.Toastify__toast--warning {
    background: #f1c40f;
}

.Toastify__toast--error {
    background: lightcoral;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast {
        border-radius: 0;
    }
}

/* team-settings-wrap */

.team-settings-content {
    border-radius: 10px;
    margin: 0;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 35px 45px 20px 60px;
}

.team-settings-content .customization-form-row {
    margin-bottom: 30px;
}

/* questions-page__toggle-flex */

.questions-page__toggle-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.questions-page__toggle-flex .icon-wrap {
    margin-right: 35px;
}

.questions-page__text p {
    padding: 20px;
    margin-top: 20px;
    border: 1px solid #dbe3e3;
    border-radius: 10px;
    margin-bottom: 0;
}

@media screen and (min-width: 768px) {
    .private-page-container {
        padding-top: 145px;
    }
    .section-padding-sm {
        padding-top: 70px;
    }
}

@media screen and (min-width: 768px) and (max-width: 930px) {
    .private-page-container {
        padding-left: 80px !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1240px) {
    .private-page-container {
        padding-left: 100px !important;
    }
}

@media screen and (min-width: 1240px) {
    .private-page-container {
        padding-left: 80px !important;
    }
}

@media screen and (min-width: 1600px) {
    .private-page-container {
        padding-left: 120px !important;
    }
}
@media screen and (max-width: 991px) {
    .report-table td:first-child {
        border-top: none !important;
    }

    .report-table tbody tr:first-child th {
        border-left: none;
    }

    .report-table-hiring-title {
        border-left: none !important;
    }

    .report-table td:last-child {
        border-top: 1px solid #dee2e6 !important;
    }

    .report-table thead {
        width: 40%;
    }

    .report-table thead tr{
        width: 100%;
    }

    .report-table thead th {
        width: 100%;
        overflow-x: auto;
        padding: 10px 5px !important;
        vertical-align: middle;
    }

    .report-table tbody th {
         padding: 0.75rem !important;
         border-left: 1px solid #dee2e6 !important;
     }

    .report-table tbody td {
        padding: .75rem;
    }
}

@media screen and (max-width: 576px) {
    .report-table {
        width: 100% !important;
    }
}

@media screen and (min-width: 991px) {
    .report-table-hiring-title {
        border-top: none !important;
    }
}

.report-page .report-page-filter-type-selector {
    width: 200px !important;
    margin-left: 15px;
}

.report-page .page-header-container {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.report-table th {
    color: #868c93  !important;
}

.report-table td {
    color: #06b291 !important;
}

.report-table thead th:hover {
    cursor: pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
}

.report-table {
    margin: 0 !important;
    -webkit-justify-content: center;
            justify-content: center;
}

.table-responsive {
    overflow-x: visible !important;
    overflow-x: initial !important;
}

.report-table-wrap {
    overflow: auto;
}
.create-hiring-state-input{
  margin-right: 20px;
}

.create-hiring-state-layout{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (max-width: 400px){
    .create-hiring-state-layout-main{
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
    }
    .create-hiring-state-layout{
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
    }
    .create-hiring-state-input{
      width: 100%;
      margin-right: 0;
    }
}

@media screen and (max-width: 600px) and (min-width: 401px){
  .create-hiring-state-layout{
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
}

.create-hiring-state-layout-main{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.all-vacancies-page{
    background-color: #eff4f4;
}

.text-color-dark-blue {
    color: #354169;
}

.all-vacancies-content {
   padding-top: 50px;
   min-height: calc(100vh - 190px);
   padding-bottom: 30px;
}

.time-filters-wrapper {
    margin-bottom: 30px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.vacancy-counter {
    margin: 0;
    font-size: 16px;
}

.allvacancy-item-city {
    margin-bottom: 8px;
}

.main-page-title-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 30px;
}

.allvacancy-item-wrapper {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
}

.allvacancy-item-title-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 7px;
}

.allvacancy-item-title {
    color: #02a88d;
    margin: 0px;
}

.allvacancy-item-desc * {
    font-size: 16px !important;
    color: #74757B !important;
    line-height: 22px;
}

.allvacancy-item-title:hover {
    text-decoration: underline;
}

.allvacancy-item-desc-wrapper {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.allvacancy-item-exp {
    font-size: 15px;
    color: #a5aebd;
}

.allvacancy-item-salary {
    font-size: 16px;
    text-align: right;
}

.allvacancy-item-desc {
    overflow-wrap: break-word;
    max-height: 65px;
    min-height: 25px;
    overflow: hidden;
    position: relative;
    line-height: 16px;
}

.allvacancy-item-desc-ellipsis {
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: 0;
    width: 50px;
    text-align: right;
    background: linear-gradient(to right, rgba(255, 255, 255, .5), #fff 70%);
}

.allvacancy-item-date {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    word-wrap: none;
    min-width: 80px
}

@media screen and (max-width: 992px) {
    .main-page-title-wrapper {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .all-vacancies-title {
        margin-bottom: 10px;
        font-size: 30px;
    }
    
}

@media screen and (max-width: 768px) {

    .time-filter {
        margin-bottom: 10px;
    }
    
    .all-vacancies-content {
        padding-bottom: 17px;
     }
}

@media screen and (max-width: 580px) {
    .time-filters-wrapper {
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }

    .time-filter {
        margin-bottom: 10px;
    }

    .allvacancy-item-salary {
        font-size: 14px;
    }

}

@media screen and (max-width: 500px) {
    .allvacancy-item-desc-wrapper {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .allvacancy-item-date {
        margin: 10px 0 0 0;
        -webkit-justify-content: left;
                justify-content: left;
        color: #a5aebd;
    }

}
.header {
    display: -webkit-flex;
    display: flex;
    background-color: #202535;
    -webkit-justify-content: center;
            justify-content: center;
}

.header-wrapper {
    padding: 0;
}

.header-nav-menu {
    list-style: none;
    margin: 0;
    padding: 0
}

.logo-wrapper:hover {
    transition: .5s;
}

.header-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 5px;
    padding-right: 15px;
}

.header_nav-menu-item {
    display: inline-block;
    transition: color 0.5s ease;
}

.header_nav-menu-item:last-child {
    color: #fff;
    border-radius: 15px;
    border: 1px solid #0dc1b6;
    padding: 5px 10px;
}

.header_nav-menu-item:last-child:hover {
    background-color: #0dc1b6; 
}

.header_nav-menu-item:last-child:hover .header_nav-menu-item-link{
    color: #fff;
}

.header_nav-menu-item-link {
    display: block;
    padding: 0 15px;
    transition: color 0.5s ease;
    color: #fff;
}

.navigation-logo {
    background-size: 60%;
    margin: 0;
    background-position-y: 41%;
}

.menu-logo-public {
    display: -webkit-flex;
    display: flex;
    width: 250px;
    height: 80px;
    background: #202535 url(/static/media/logo_white.b213b02a.svg) no-repeat 20px 50%;
    background-size: 69%;
    cursor: pointer;
}

@media screen and (max-width: 574px) {
    .menu-logo-public {
        width: 80px;
        height: 35px;
        background: #202535 url(/static/media/logo_white_adaptive.b743048d.svg) no-repeat 20px;
    }

    .header_nav-menu-item-link {
        font-size: 12px;
    }

    .header {
        padding: 15px 0;
    }
}
.vacancy-content {
    min-height: 78vh;
}

.vacancy-page-description {
    font-size: 18px;
}

.vacancy-page-main-title {
    font-size: 24px;
}

.vacancy-page-required {
    font-size: 16px;
    color: #74757B;
}

.vacancy-page-desc {
    font-size: 18px;
    color: #354169;
    line-height: 1.2;
}

.vacancy-page-part {
    margin-bottom: 40px;
}

