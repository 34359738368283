@media screen and (max-width: 991px) {
    .report-table td:first-child {
        border-top: none !important;
    }

    .report-table tbody tr:first-child th {
        border-left: none;
    }

    .report-table-hiring-title {
        border-left: none !important;
    }

    .report-table td:last-child {
        border-top: 1px solid #dee2e6 !important;
    }

    .report-table thead {
        width: 40%;
    }

    .report-table thead tr{
        width: 100%;
    }

    .report-table thead th {
        width: 100%;
        overflow-x: auto;
        padding: 10px 5px !important;
        vertical-align: middle;
    }

    .report-table tbody th {
         padding: 0.75rem !important;
         border-left: 1px solid #dee2e6 !important;
     }

    .report-table tbody td {
        padding: .75rem;
    }
}

@media screen and (max-width: 576px) {
    .report-table {
        width: 100% !important;
    }
}

@media screen and (min-width: 991px) {
    .report-table-hiring-title {
        border-top: none !important;
    }
}

.report-page .report-page-filter-type-selector {
    width: 200px !important;
    margin-left: 15px;
}

.report-page .page-header-container {
    justify-content: flex-start;
}

.report-table th {
    color: #868c93  !important;
}

.report-table td {
    color: #06b291 !important;
}

.report-table thead th:hover {
    cursor: pointer;
    user-select:none;
}

.report-table {
    margin: 0 !important;
    justify-content: center;
}

.table-responsive {
    overflow-x: initial !important;
}

.report-table-wrap {
    overflow: auto;
}