.modal {
    text-align: center;
}

.modal-dialog {
    display: inline-table;
    margin: 130px auto;
    padding: 0 20px;
    text-align: initial;
}

.modal-hh-import-wrapper {
    max-width: 550px;
    width: 100%;
    max-height: 200px;
}

.modal-content {
    border-radius: 10px;
}

.modal-title {
    color: #354169;
    font-size: 24px;
    font-weight: 700;
}

.modal-header {
    padding: 50px 15px 40px;
    border-bottom: none;
}

.modal-body {
    padding: 0 15px;
    border-bottom: none;
}

.modal-footer {
    display: flex;
    flex-direction: column;
    align-items: unset;
    padding: 50px 15px;
    border-top: none;
}

.modal-window__footer {
    padding: 25px;
}

.modal-header .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    background-color: #14ccae;
    line-height: unset;
    padding: 0 0 2px;
    border-radius: 50%;
    opacity: 1;
    color: #fff;
    transition: background-color 0.3s;
}

.modal-header .close:hover,
.modal-header .close:not(:disabled):not(.disabled):hover{
    color: #fff;
    background-color: #02a88d;
    opacity: 1;
}

.modal-body {
    color: #aab3bd;
    font-size: 16px;
    font-weight: 400;
}

.modal-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.modal-flex .buttons-container {
    width: 100%;
}

.modal-flex .form-error {
    text-align: left;
}

@media screen and (min-width: 768px) {
    .modal-dialog {
        padding: 0;
    }
    .modal-header {
        padding: 50px 50px 40px;
    }
    .modal-body {
        padding: 0 50px;
    }
    .modal-footer {
        flex-direction: row;
        align-items: center;
        padding: 50px;
    }
    .modal-flex .buttons-container {
        width: unset;
    }
    .modal-flex .form-error {
        text-align: right;
    }
    .modal-dialog {
        min-width: 500px;
    }
}
