.payment-form {
    padding: 0 15px 40px;
    border-bottom: 1px solid #dfe9e9;
}

.payment-info {
    padding: 35px 15px 30px;
    background-color: #f3f7f7;
}

.payment-info-top {
    margin-bottom: 40px;
}

.payment-info-bottom {
    display: flex;
}

.payment-period > label {
    color: #b7beca;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
    margin-bottom: 14px;
}

.payment-email {
    margin-bottom: 35px;
}

.payment-company {
    margin-bottom: 35px;
}

.payment-period {
    margin-bottom: 45px;
}

.payment-text {
    color: #354169;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.promo-code-wrap .form-control {
    max-width: 318px;
    height: 40px;
    line-height: 40px;
    color: #b7beca;
    font-size: 14px;
    margin-bottom: 15px;
}

.payment-email label,
.promo-code-wrap .render-field-container label {
    color: #b7beca;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
    margin-bottom: 14px;
}

.promo-code-wrap .form-control::-webkit-input-placeholder {
    color: #b7beca;
}
.promo-code-wrap .form-control::-moz-placeholder {
    color: #b7beca;
}
.promo-code-wrap .form-control:-ms-input-placeholder {
    color: #b7beca;
}
.promo-code-wrap .form-control:-moz-placeholder {
    color: #b7beca;
}

.payment-form form {
    margin-bottom: 20px;
}

.promo-code-wrap .form-group {
    margin-bottom: 0;
}

.payment-text.text-margin {
    margin-bottom: 45px;
}

.promo-code-wrap .button {
    height: 40px;
    padding: 0 20px;
    font-size: 12px;
    flex-shrink: 0;
}

.payment-info-text {
    color: #354169;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-bottom: 35px;
}

.payment-info-text-email {
    color: #354169;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.35px;
}

.payment-info-list {
    padding: 0;
    list-style: none;
    margin-bottom: 40px;
}

.payment-info-list-item {
    position: relative;
    padding-left: 20px;
    margin-bottom: 25px;
}

.payment-info-list-item:last-child {
    margin-bottom: 0;
}

.payment-info-list-item:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 5px;
    height: 2px;
    background-color: #26b9a1;
}

.payment-info-list-item-title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.4px;
    color: #354169;
}

.payment-info-list-item-text {
    font-size: 12px;
    color: #a0abbd;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.payment-info-requisites {
    display: block;
    color: #42bca1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.35px;
    cursor: pointer;
}

.payment-info-requisites:hover {
    color: #047b63;
    transition: color 0.3s ease;
}

.payment-info-bottom .icon-wrap {
    margin: 3px 17px 0 0;
}

.payment-info-bottom .icon-wrap .item-icon {
    color: #42bca1;
}

.modal-payment.modal-dialog {
    max-width: 390px;
}

.modal-payment.modal-dialog .modal-title {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: 1.2;
}

.modal-payment.modal-dialog .modal-header {
    padding: 40px 40px 45px;
}

.modal-payment.modal-dialog .modal-body {
    padding: 0 40px 40px;
}

.modal-payment.modal-dialog .modal-body p {
    color: #354169;
    font-size: 16px;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .payment-period {
        margin-bottom: 40px;
    }
    .payment-form {
        max-width: 50%;
        border-bottom: none;
        padding: 15px 15px 0 0;
    }
    .payment-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 360px;
        border-radius: 10px;
        border: 1px solid #dbe0e8;
        background-color: #f3f7f7;
    }
    .payment-info-top {
        margin-bottom: 0;
    }
    .payment-info-requisites {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 992px) {
    .payment-form {
        width: 100%;
        max-width: 460px;
        padding-left: 15px;
    }
    .payment-info {
        padding: 35px 15px 30px 30px;
    }
    .payment-text a.light-green {
        display: block;
    }
    .promo-code-wrap > form {
        display: flex;
        align-items: flex-end;
    }
    .promo-code-wrap .form-control {
        margin-bottom: 0;
    }
    .promo-code-wrap .button {
        margin-left: 10px;
    }
    .payment-title {
        padding: 0 45px;
    }
}

@media screen and (min-width: 992px) {
    .payment-form {
        max-width: 500px;
    }
}