code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.2;
    font-family: 'Ubuntu', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    overflow-x: hidden;
}

.private-page-container {
    min-height: calc(100vh - 110px);
    padding: 115px 15px 45px;
}

.default-text {
    color: #354169;
}

.loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
}

.transparent_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

/* Custom styles */

.login-error-message {
    padding-top: 35px;
    color: #d86155;
    font-size: 14px;
    letter-spacing: 0.35px;
}

.sign-up-error-message {
    margin: 10px 0;
    color: #e02730;
}

.customization-page-title {
    text-align: center;
}

.widget-customization-color {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0 20px;
}

.widget-customization-color-button-container {
    display: flex;
    justify-content: center;
}

.widget-customization-color-button-container > Button {
    margin: 0 5px;
}

.color-picker {
    position: relative;
    display: inline-block;
}

.color-picker-box {
    width: 20px;
    height: 20px;
    border: 1px solid #a9a9a9;
    cursor: pointer;
}

.picker-popover {
    position: absolute;
    z-index: 2;
}

.picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.edit-hiring-template-stages {
    margin: 20px 0;
}

.hiring-stage-item-container {
    display: flex;
    align-items: center;
}

.hiring-stage-item-input {
    max-width: 500px;
    margin: 5px 20px 5px 0;
}

.render-field-input {
    margin-bottom: 5px;
}

.render-field-error {
    color: red;
}

.render-actions-setup-header {
    display: flex;
    justify-content: space-between;
}

.status-message-request {
    padding: 15px;
}

.status-message-success {
    padding: 15px;
    color: green;
}

.status-message-fail {
    padding: 15px;
    color: red;
}

.not-found-page-container {
    margin: 30px 0;
    font-size: 18px;
}

.error-message-container {
    margin: 10px 0;
    color: red;
}

.login-password-restore-link-container a {
    font-size: 12px;
}

.demo-widget-container {
    border: 1px solid #dcdcdc;
}

@media screen and (max-width: 768px) {
    .widget-color-picker {
        width: calc((100% - 20px) / 3);
    }
}

@media screen and (max-width: 600px) {
    .widget-color-picker {
        width: calc((100% - 20px) / 2);
    }
}

/* hide spin box */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
    color: #fff;
    outline: none;
    opacity: 1;
}

/* =>>> */

.section-padding-sm {
    padding: 45px 15;
}

.label {
    color: #a3a4a9;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
}

.light-green {
    color: #26b9a1;
}

.dark-blue {
    color: #354169;
}

/* success-block */

.success-block-title {
    font-size: 24px;
    color: #354169;
    font-weight: 400;
    letter-spacing: .6px;
    margin-bottom: 40px;
}

.success-block-link {
    font-size: 16px;
}

/* 3rd party styles */

.mce-fullscreen.mce-container {
    border-radius: 0 !important;
}

.mce-container {
    border-radius: 23px !important;
    background: #eff4f4 !important;
    box-shadow: none !important;
    border: none !important;
}

div.mce-edit-area {
    background: #eff4f4 !important;
    padding: 10px 20px !important;
    color: #354169 !important;
}

.mce-btn {
    border: none !important;
    background: #eff4f4 !important;
    border-radius: 10px !important;
    opacity: 0.5;
    transition: opacity 0.2s ease;
}

.mce-btn:hover {
    color: #354169 !important;
    opacity: 1 !important;
}

.mce-btn.mce-active i {
    color: #02a88d !important;
    opacity: 1 !important;
}

.mce-active .mce-txt {
    color: #354169 !important
}

.mce-btn-group:not(:first-child) {
    border: none !important;
}

.mce-btn.mce-disabled button:hover {
    cursor: not-allowed !important;
}

.mce-primary button {
    color: #595959 !important;
}

.mce-statusbar,
.mce-menubar,
.mce-menu-item-sep {
    display: none !important;
}

.mce-toolbar,
.mce-stack-layout-item,
.mce-top-part::before {
    box-shadow: none !important;
}

.mce-toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.mce-menu-item {
    background-color: #fff !important;
}

.mce-fixed-width span {
    width: 30px !important;
}

.mce-tinymce iframe {
    min-height: 200px;
}

.mce-menu-item.mce-active.mce-menu-item-normal .mce-text {
    color: #06b291 !important;
}

.Toastify__toast {
    border-radius: 5px;
    font-family: 'Ubuntu', sans-serif;
}

.Toastify__toast-body {
    padding: 5px;
    padding-left: 8px;
    margin: auto 0;
    flex: 1 1;
}

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}

.Toastify__toast--success {
    background: lightseagreen;
}

.Toastify__toast--warning {
    background: #f1c40f;
}

.Toastify__toast--error {
    background: lightcoral;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast {
        border-radius: 0;
    }
}

/* team-settings-wrap */

.team-settings-content {
    border-radius: 10px;
    margin: 0;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 35px 45px 20px 60px;
}

.team-settings-content .customization-form-row {
    margin-bottom: 30px;
}

/* questions-page__toggle-flex */

.questions-page__toggle-flex {
    display: flex;
    align-items: center;
}

.questions-page__toggle-flex .icon-wrap {
    margin-right: 35px;
}

.questions-page__text p {
    padding: 20px;
    margin-top: 20px;
    border: 1px solid #dbe3e3;
    border-radius: 10px;
    margin-bottom: 0;
}

@media screen and (min-width: 768px) {
    .private-page-container {
        padding-top: 145px;
    }
    .section-padding-sm {
        padding-top: 70px;
    }
}

@media screen and (min-width: 768px) and (max-width: 930px) {
    .private-page-container {
        padding-left: 80px !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1240px) {
    .private-page-container {
        padding-left: 100px !important;
    }
}

@media screen and (min-width: 1240px) {
    .private-page-container {
        padding-left: 80px !important;
    }
}

@media screen and (min-width: 1600px) {
    .private-page-container {
        padding-left: 120px !important;
    }
}