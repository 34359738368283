.main-page-title {
    color: #b7beca;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    word-wrap: break-word;
}

h3 {
    color: #354169;
    font-size: 24px;
    font-weight: 700;
}

h4 {
    color: #354169;
    font-size: 18px;
    letter-spacing: 0.45px;
    margin-bottom: 0;
    font-weight: normal;
}

.title {
    color: #354169;
    font-size: 24px;
    font-weight: 700;
    word-wrap: break-word;
}

.item-text {
    color: #354169;
    font-size: 18px;
    word-wrap: break-word;
}
.item-text_color_grey {
    color: #9ba2b0;
}


@media screen and (min-width: 768px) {
    .main-page-title {
        font-size: 36px;
    }
}
