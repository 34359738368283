.team-container-collapse {
    width: 100%;
}

.team-container-toggle {
    display: flex;
    justify-content: space-between;
    padding: 0 0 35px;
}

.team-container-toggle-header {
    width: calc(100% - 48px);
}

.teams-item-tariff {
    font-size: 14px;
    font-weight: 400;
    color: #354169;
}

.team-name-editor-container {
    display: flex;
    min-height: 40px;
    margin-bottom: 10px;
}

.team-name-editor-container-button-right {
    margin-left: auto;
    margin-right: 8px;
}

.team-name-editor-container .icon-wrap {
    width: 28px;
    height: 28px;
    padding: 0;
    line-height: 28px;
    background-color: #eff4f4;
    border-radius: 50%;
    text-align: center;
    margin-left: auto;
    flex-shrink: 0;
    cursor: pointer;
}

.team-name-editor-container .icon-wrap.icon-editor {
    margin-left: unset;
}

.team-name-editor-container-title {
    padding: 7px 30px 7px 0;
    font-weight: 700;
    word-wrap: break-word;
    min-width: 0;
}

.active-team-toggle-selector {
    min-width: 180px;
    max-width: 230px;
}

.customization-form-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 5px 0;
}

/* table */

table.teams-table {
    display: flex;
    overflow: hidden;
    width: unset;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 0 35px;
    margin-bottom: 0;
}

table.teams-table thead {
    display: flex;
    flex-shrink: 0;
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
    padding-bottom: 10px;
}

table.teams-table tr {
    display: flex;
    flex-direction: column;
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
    flex-shrink: 0;
}

table.teams-table tbody {
    display: flex;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 10px;
}

table.teams-table thead th {
    color: #aab3bd;
    font-size: 14px;
    font-weight: 400;
    border-bottom: none;
    padding: 12px 23px;
    height: 40px;
    border-left: none;
}

table.teams-table td, table.teams-table th {
    padding: 12px 45px 12px 20px;
    height: 40px;
    color: #354169;
    font-size: 13px;
    font-weight: 400;
    border-left: 1px solid #dee2e6;
    word-wrap: break-word;
}

table.teams-table th:first-child,
table.teams-table td:last-child {
    border-top: none;
}

table.teams-table td.team-table-td-without-delete {
    border-left: none;
    display: none;
}

td.team-table-td-with-delete{
    text-align: end;
}

@media screen and (min-width: 992px){
    table.teams-table td.team-table-td-with-delete {
        border-left: none;
    }
}

@media screen and (max-width: 991px){
    td.team-table-td-with-delete{
        text-align: start;
    }
}

table.teams-table th:first-child {
    width: 100%;
}
.teams-table_status-row{
    text-align: center;
    border-top: none !important;
}

/* buttons */

.team-container-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.team-container-buttons-container .button {
    margin: 15px 15px 15px 0;
}

.team-name-editor-container .form-group {
    margin: 0 10px 0 0;
}

@media screen and (min-width: 768px) {
    .team-container-toggle {
        padding: 0;
    }
    table.teams-table {
        margin-right: 0;
        margin-left: 0;
        padding: 35px 0;
    }
    .team-container-buttons-container .button {
        margin: 0;
    }
    .team-container-toggle-header {
        width: calc(100% - 56px);
    }
    .job-link-button {
        font-size: 16px;
    }
}

@media screen and (min-width: 992px) {
    .team-container-buttons-container {
        justify-content: space-between;
    }
    table.teams-table {
        display: table;
        width: 100%;
        margin: 35px 0;
    }
    table.teams-table thead {
        display: table-header-group;
    }
    table.teams-table tr {
        display: table-row;
    }
    table.teams-table td {
        display: table-cell;
    }
    table.teams-table tbody {
        display: table-row-group;
    }
    table.teams-table tr:last-child th:first-child {
        border-bottom: none;
    }
    table.teams-table thead tr:last-child th:first-child {
        border-bottom: 1px solid #dee2e6;
    }
    table.teams-table thead tr th {
        border-left: 1px solid #dee2e6;
        border-top: none;
        padding-left: 19px;
    }
    table.teams-table th:first-child {
        width: 175px;
        border-left: none;
        border-bottom: 1px solid #dee2e6;
    }
    table.teams-table th {
        width: 175px;
    }
    table.teams-table td:last-child {
        width: 50px;
        border-top: 1px solid #dee2e6;
    }
    table.teams-table td, table.teams-table th {
        padding: 12px 20px 12px 20px;
    }
    table.teams-table .teams-table-delete {
        border-left: none;
    }
    table.teams-table thead th {
        border-bottom: 1px solid #dee2e6;
    }
    table.teams-table td:first-child {
        text-align: center;
        border-left: none;
    }
    .teams-table_status-row {
        width: 10%;
        border-top: 1px solid #dee2e6 !important;
    }
}

.job-link-button {
    border-radius: 23px;
    background-color: #eff4f4;
    padding: 8px 22px;
    color: #354169;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.job-link {
    display: inline-block;
    margin-left: 5px;
    max-width: 380px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}

.job-link a {
    color: #b7beca;
}

.job-link-content {
    display: inline;
}

.job-link-content:after {
    content: ':';
}

.button-add-worker {
    margin-bottom: 15px;
}

@media screen and (max-width: 1200px) {
    .job-link, .job-link-content:after {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .button-add-worker {
        width: 100%;
        text-align: right;
    }
}

@media screen and (max-width: 767px) {
    .job-link-button {
        order: -1;
    }
    .button-add-worker {
        margin-bottom: 0;
    }
    .team-container-buttons-container .button {
        margin-right: 0;
    }
}

@media screen and (max-width: 400px) {
    .team-container-buttons-container {
        flex-direction: column;
        align-items: flex-start;
    }
    .button-add-worker {
        order: -2;
        margin-bottom: 15px;
    }
    .team-container-buttons-container .button, .job-link-button {
        width: 100%;
        margin-bottom: 0;
    }
}

.job-link-button .item-icon {
    margin-left: 5px;
}

@media screen and (max-width: 968px) {
    .widget-code-modal .modal-content {
        width: 90vw;
    }
}

@media screen and (max-width: 768px) {
    .widget-code-modal__copy-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.job-link-button .copy-icon {
    margin-left: 7px;
}

.invite-employee-error {
    margin-top: 20px;
    color: red;
}