.pagination-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-top: 30px;
}

.pagination-custom {
    display: flex;
    padding: 0;
    margin: 0 0 20px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: #ffffff;
}

.pagination-custom a {
    display: block;
    width: 30px;
    text-align: center;
    font-size: 14px;
    padding: 10px 5px;
    cursor: pointer;
    user-select: none;
    color: #c1c7d1;
}

.pagination-custom li {
    list-style: none;
    cursor: pointer;
    padding: 0;
}

.pagination-custom li.active a {
    color: #06b291;
}

.pagination-custom a:hover {
    color: #354169;
}

.pagination-custom__arrow {
    font-size: 11px;
    color: #06b291;
}

.pagination-container__prev,
.pagination-container__next {
    position: relative;
}

.pagination-container__prev:before,
.pagination-container__next:before {
    display: block;
    content: '';
    position: absolute;
    top: 5px;
    right: 0;
    width: 1px;
    height: 25px;
    background-color: #dfe9e9;
}

.pagination-container__next:before {
    right: unset;
    left: 0;
}

.items-to-show {
    display: flex;
    align-items: center;
}

.items-to-show__text {
    color: #a5aebd;
    font-size: 12px;
}

.items-to-show__select {
    margin-left: 20px;
}

.items-to-show__select .react-select__control {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    background-color: #fff;
}

.items-to-show__select.react-select-menu-is-close div.react-select__control--is-focused {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}

.items-to-show__select div.react-select__control {
    height: 35px;
    min-height: 35px;
}

.items-to-show__select div.react-select__value-container {
    padding: 0 15px 0;
}

.items-to-show__select div.react-select__single-value {
    font-size: 12px;
}

.items-to-show__select div.react-select__dropdown-indicator {
    padding-right: 14px;
}

.items-to-show__select div.react-select__dropdown-indicator svg {
    width: 12px;
    height: 12px;
}

.items-to-show__select div.react-select__option {
    padding-left: 20px;
    font-size: 12px;
}

@media screen and (min-width: 576px) {
    .pagination-custom a {
        width: 40px;
    }
}

@media screen and (min-width: 768px) {
    .pagination-container {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .pagination-custom {
        margin-bottom: 0;
    }
}