.vacancy-content {
    min-height: 78vh;
}

.vacancy-page-description {
    font-size: 18px;
}

.vacancy-page-main-title {
    font-size: 24px;
}

.vacancy-page-required {
    font-size: 16px;
    color: #74757B;
}

.vacancy-page-desc {
    font-size: 18px;
    color: #354169;
    line-height: 1.2;
}

.vacancy-page-part {
    margin-bottom: 40px;
}
