.tariff__title {
    margin-bottom: 35px;
}

.tariff__features-list {
    margin-bottom: 50px;
}

.tariff__subtitle {
    margin-bottom: 15px;
}

.tariff__payment-option-container {
    display: flex;
    margin-bottom: 45px;
}

.tariff__payment-option {
    width: 50%;
    max-width: 180px;
}

.tariff__price-container {
    margin-bottom: 5px;
}

.tariff__price {
    display: inline-block;
    color: #354169;
    font-size: 24px;
    font-weight: 700;
    margin-right: 3px;
}

.tariff__currency {
    display: inline-block;
    font-size: 19px;
    color: #354169;
    font-weight: 700;
}

.tariff__payment-option-text {
    color: #9ba3b1;
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 10px;
}

.tariff__button {
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .tariffs-container {
        display: flex;
        justify-content: space-between;
    }
}