.widget-setup-page__title {
    font-size: 36px;
    margin-bottom: 40px;
}

.customization-list {
    padding: 35px 20px;
    background-color: #fff;
    margin-right: -15px;
    margin-left: -15px;
}

.customization-container {
    margin-bottom: 30px;
}

.customization-container:last-child {
    margin-bottom: 0;
}

.customization-container__title {
    margin-bottom: 30px;
}

.customization-container-settings-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.customization-item {
    padding: 10px 0 20px;
    width: 100%;
    max-width: 300px;
}

.customization-item__language-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.customization-item__language-container .customization-item-title {
    margin: 0;
}

.customiztion-item__language-select {
    max-width: 150px;
    width: 100%;
    margin-left: 10px
}

.customization-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
}

.customization-item-title {
    font-size: 16px;
    color: #354169;
    margin-bottom: 7px;
}

.customization-item-description {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.customization-item-description .item-icon {
    margin-right: 8px;
    cursor: auto;
    color: #b7beca;
}

.customization-item-description-text {
    color: #b7beca;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.customization-container-settings-container .size-selector-container {
    width: 70px;
}

.customization-container-settings-container div.react-select__control {
    min-height: 33px;
    height: 30px;
    border-radius: 15px;
}

.customization-container-settings-container div.react-select__value-container {
    padding: 0 0 0 12px;
}

.customization-container-settings-container div.react-select__dropdown-indicator {
    padding: 0 12px 0 0;
}

/* form */

.customization-form-input {
    max-width: 250px;
}

.customization-form-select {
    height: 35px;
    width: 130px;
}

.customization-form-button {
    margin: 10px 0;
}

.customization-form-checkbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* widget */

.customization-widget {
    padding: 25px 15px 30px;
}

.customization-widget-content {
    margin-right: -15px;
    margin-left: -15px;
    background-color: #fff;

}

@media screen and (min-width: 576px) {
    .customization-list,
    .customization-widget-content {
        margin: 0;
    }
}

@media screen and (min-width: 768px) {
    .customization-item {
        max-width: 250px;
    }
}

@media screen and (min-width: 992px) {
    .customization-widget {
        padding-top: 0;
    }
}

@media screen and (min-width: 1200px) {
    .customization-list {
        padding: 35px 20px 20px 20px;
    }
    .customization-setting,
    .customization-widget {
        padding: 0 15px;
    }
}

@media screen and (min-width: 1200px) {
    .widget-setup-page__title {
        font-size: 42px;
    }
}

.url-container-toggle {
    display: flex;
    justify-content: space-between;
}

.url-container-toggle-header {
    width: calc(100% - 48px);
}

.team-url-editor-container {
    display: flex;
    min-height: 40px;
    margin-bottom: 10px;
    justify-content: space-between;
    width: 100%;
}

.team-url-editor-container .form-group {
    margin-bottom: 10px;
}

.team-url-editor-container-title {
    padding: 7px 30px 7px 0;
    font-weight: lighter;
    color: #354169;
}
.team-url-editor-container-description {
    color: #354169;
}

.customization-container-captcha-switch{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.vacancy-item-switch-captcha{
    padding: 7px;
}