/* page-header-container  */

.page-header-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.page-header-container__title {
    margin-bottom: 25px;
}
.page-header-container__filter {
    width: 100%;
}

@media screen and (min-width: 576px) {
    .page-header-container {
        padding: 0;
        margin-bottom: 35px;
    }
    .page-header-container__title {
        margin-bottom: 0;
    }
    .page-header-container__filter {
        max-width: 291px;
    }
}
@media screen and (max-width: 576px) {
    .page-header-container {
        margin-bottom: 10px;
    }
    .responses-wrap .page-header-container {
        margin-bottom: 40px;
    }
}

/* content-container */

.content-container-list__item {
    margin-bottom: 30px;
}
.content-container-list__item:last-child {
    margin-bottom: 0;
}

.content-container {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    padding: 35px 15px;
    background-color: #fff;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}
.content-container_padding_sm {
    padding: 0 15px;
}
.content-container_for-page-tariffs {
    padding: 50px 15px;
    margin-bottom: 40px;
}
.content-container_for-page-payment {
    padding: 40px 0 0;
}
.content-container__item {
    padding: 30px 0 25px;
    border-bottom: 1px solid #dfe9e9;
}
.content-container__item:last-child {
    border-bottom: none;
}
.content-container__empty {
    padding: 30px 0 25px;
    font-size: 16px;
    color: #354169;
}
.content-container__flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.content-container__top-line {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 5px;
    background-color: #fad16f;
}
.content-container__top-line_color_red {
    background-color: #f9764e;
}

@media screen and (min-width: 576px) {
    .content-container {
        margin-left: 0;
        margin-right: 0;
        border-radius: 10px;
    }
    .content-container_for-page-tariffs {
        padding: 50px;
    }
    .content-container-list__item {
        margin-bottom: 30px;
    }
    .content-container__top-line {
        right: 50px;
        left: 50px;
    }
}
@media screen and (min-width: 768px) {
    .content-container_for-page-tariffs {
        width: 47%;
        margin-bottom: 0;
    }
    .content-container_for-page-payment {
        display: flex;
        justify-content: space-between;
        padding: 30px 15px;
    }
    .content-container__item {
        padding: 37px 0 32px;
    }
}
@media screen and (min-width: 992px) {
    .content-container {
        padding: 45px 50px;
    }
    .content-container_padding_sm {
        padding: 13px 50px 23px;
    }
    .content-container_for-page-payment {
        padding: 30px;
    }
    .content-container__item {
        padding: 37px 0 35px;
    }
}

/* list(tariffs) */

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.list__item {
    position: relative;
    color: #354169;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 20px;
}
.list__item:last-child {
    margin-bottom: 0;
}
.list__item:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 5px;
    height: 2px;
    background-color: #26b9a1;
}

/* frame-text  */

.frame-text {
    padding: 20px;
    border: 1px solid #dbe3e3;
    border-radius: 10px;
}

.frame-text > p {
    margin-bottom: 0;
}

/* errors */

.form-error {
    color: #d86155;
    padding: 5px 0;
}

@media screen and (min-width: 768px) {
    .form-error {
        font-size: 16px;
    }
}