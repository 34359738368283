.item-icon {
    cursor: pointer;
    font-size: 16px;
    color: #c3c9d0;
}


/* TOOLTIP */

.tooltip-custom {
    display: inline-block;
    padding: 0 10px;
}

.tooltip-import-hh-vacancies {
    padding: 0;
}

.tooltip-custom__window {
    background-color: #2e354c;
    opacity: 1;
    border-radius: 7px;
    font-family: 'Ubuntu', sans-serif;
    color: #eff4f4;
}
.tooltip-custom__window_default {
    max-width: 310px;
    padding: 10px 12px;
}

.tooltip-custom__window_sm {
    max-width: 205px;
    padding: 5px 5px;
}

.tooltip-custom__arrow_top:before {
    border-top-color: #2e354c !important;
}

.tooltip-custom__arrow_right:before {
    border-right-color: #2e354c !important;
}

.main-page-title__tooltip {
    font-size: 17px;
}

@media screen and (min-width: 768px) {
    .main-page-title__tooltip {
        font-size: 24px;
    }
}
