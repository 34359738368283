/* Отклики */

.response-item-title {
    margin-bottom: 25px;
}

.response-item-new-label {
    color: #e02730;
    margin-bottom: 2px;
}

.response-item-position {
    color: #354169;
    font-size: 18px;
    margin-bottom: 7px;
    word-wrap: break-word;
}

.response-item-name-link {
    color: #26b9a1;
    font-size: 24px;
    word-wrap: break-word;
    max-width: 90%;
}

.responses-wrap .react-select-menu-is-close div.react-select__control {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}

.responses-wrap div.react-select__control {
    background-color: #fff;
}

@media screen and (min-width: 992px) {
    .response-item-position {
        font-size: 18px;
    }
    .response-item-name-link {
        font-size: 30px;
    }
}