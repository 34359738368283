.create-hiring-state-input{
  margin-right: 20px;
}

.create-hiring-state-layout{
  display: flex;
  align-items: center;
}

@media screen and (max-width: 400px){
    .create-hiring-state-layout-main{
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    .create-hiring-state-layout{
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    .create-hiring-state-input{
      width: 100%;
      margin-right: 0;
    }
}

@media screen and (max-width: 600px) and (min-width: 401px){
  .create-hiring-state-layout{
    flex-direction: column;
    align-items: flex-start;
  }
}

.create-hiring-state-layout-main{
    width: 100%;
    display: flex;
    align-items: center;
}