.breadcrumbs {
    padding-bottom: 60px;
}

@media screen and (max-width: 576px) {
    .breadcrumbs {
        padding-bottom: 30px;
    }
}

.breadcrumbs__link {
    color: #b7beca;
    text-decoration: underline;
}

.breadcrumbs__divider {
    margin: 0 15px;
    color: #b7beca;
}

.breadcrumbs__item {
    color: #b7beca;
    word-wrap: break-word;
}